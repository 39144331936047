import { SpinnerSize } from "@fluentui/react";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useRecoilValue } from "recoil";

import { Button } from "../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../components/ButtonContainers";
import { Card } from "../../../components/Card";
import { DefaultLoading } from "../../../components/Loading";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../components/Modal";
import { SimpleInputModal } from "../../../components/SimpleInputModal";
import { config } from "../../../config";
import {
  AppMessagesContext,
  DataPreviewContext,
  GeographiesContext,
  ShowDraftDataContext,
} from "../../../lib/application/contexts";
import { useKeyUpEscape } from "../../../lib/application/hooks/useKeyUp";
import { handleGetDefaultMeasure } from "../../../lib/application/requests/common_requests";
import { createDocument } from "../../../lib/application/requests/docs/documents";
import {
  useAddStatsCardCallback,
  useGetAllCardsCallback,
  useRemoveCardCallback,
} from "../../../lib/application/state/actions/cardCallbacks";
import { useCreateSelectionFromSearchHit } from "../../../lib/application/state/actions/selections/useCreateSelectionFromSearchHit";
import { applyDefaultSettings } from "../../../lib/application/state/stats/default-settings/apply";
import { makeDataSelection } from "../../../lib/application/state/stats/document-core/core";
import { makeDataCardState } from "../../../lib/application/state/stats/document-core/create";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import { updateDataCardInnerImmut } from "../../../lib/application/state/stats/document-core/updates";
import { stateToWorkspaceLatest } from "../../../lib/application/state/stats/workspace/shared";
import { getText } from "../../../lib/application/strings";
import { validateNonEmptyString } from "../../../lib/application/validation/string";
import { defined } from "../../../lib/core/defined";
import { Progress } from "../../../lib/core/progress";
import {
  defaultMeasureSelectionPrimary,
  getSubjectPath,
  setMeasureAvailableDatesMut,
  tryExtractSettings,
} from "../../../lib/domain/measure";
import { TimeResolution } from "../../../lib/domain/time";
import { logger } from "../../../lib/infra/logging";
import { openDocument } from "../../../lib/paths";
import { DataCardContent } from "../docs/cards/data_card/DataCard";
import { SearchMain } from "../docs/SearchMain";

import "./DataSearchAndPreview.scss";
import { statsApi } from "../../../lib/application/requests/statsApi";
import { useLoadStatsMeasureAsNewCard } from "../../../lib/application/state/actions/load/measure";
import { cardWithUpdatedDataState } from "../../../lib/application/state/stats/cardToDataStateStats";
import { PromiseController } from "../../../lib/application/loading/load_status";
import { useExtendedAppearanceSettings } from "../../../lib/application/state/actions/useExtendedAppearanceSettings";
import { createColorSchemeContainerWithPalette } from "../../../lib/application/state/stats/document-style/operations";
import { setHomeSearchLoaded } from "../../../lib/application/state/app_load_state";

export function DataSearchAndPreview() {
  const handleAddCard = useAddStatsCardCallback();
  const handleRemoveCard = useRemoveCardCallback();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const geographies = useContext(GeographiesContext);
  const appearanceSettings = useExtendedAppearanceSettings();

  useLayoutEffect(() => {
    setHomeSearchLoaded();
  }, []);

  const handleCloseModal = useCallback(
    (cardId: string) => {
      handleRemoveCard(cardId);
    },
    [handleRemoveCard]
  );

  const handleCreateSelectionFromHit =
    useCreateSelectionFromSearchHit(adminShowDraftData);
  const handleSelectMeasureResult = useCallback(
    async (
      measureId: number,
      subjectPath: string[],
      dimensionDataColumn?: string,
      dimensionValueId?: number
    ) => {
      // Never abort fetching/processing of a preview measure -- there's not much a user
      // can do from here that makes it worth cancelling
      const shouldAbort = () => false;
      const result = await handleCreateSelectionFromHit(
        measureId,
        subjectPath,
        dimensionDataColumn,
        dimensionValueId
      );

      const cardState = makeDataCardState(
        result.subjectPath,
        result.measureSelection
        // Do not apply default settings here - we want to keep the breakdown selections from the search result
      );
      if (!defined(geographies)) {
        return;
      }
      const cardWithData = await cardWithUpdatedDataState(
        shouldAbort,
        cardState,
        geographies,
        adminShowDraftData,
        appearanceSettings,
        result.defaultSettings?.colorSchemeContainer
      );
      if (!defined(cardWithData) || shouldAbort()) {
        return;
      }
      handleAddCard(
        cardWithData,
        0,
        createColorSchemeContainerWithPalette(appearanceSettings.defaultTheme)
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geographies,
      handleAddCard,
      handleCreateSelectionFromHit,
    ]
  );

  const getDefaultMeasure = useCallback(
    (subjectPath: string[]) => {
      return handleGetDefaultMeasure(
        subjectPath,
        false,
        false,
        TimeResolution.maximal(),
        adminShowDraftData
      );
    },
    [adminShowDraftData]
  );

  const handleSelectSubjectPath = useCallback(
    async (subjectPath: string[]) => {
      const card = makeDataCardState(subjectPath, undefined);
      if (!defined(subjectPath[2])) {
        handleAddCard(
          card,
          0,
          createColorSchemeContainerWithPalette(appearanceSettings.defaultTheme)
        );
        return;
      }

      const defaultResponse = await getDefaultMeasure(subjectPath);
      if (!defined(defaultResponse)) {
        logger.warn("No default measure for subject path", subjectPath);
        return;
      }
      if (!defined(geographies)) {
        logger.warn("No geographies loaded");
        return;
      }

      const { availableMeasures, defaultMeasure } = defaultResponse;

      const settings = await tryExtractSettings(defaultMeasure);
      const defaultMeasureSelection = defaultMeasureSelectionPrimary(
        defaultMeasure,
        availableMeasures,
        settings
      );
      const dataSelection = makeDataSelection(subjectPath);
      return setMeasureAvailableDatesMut(
        defaultMeasureSelection,
        adminShowDraftData
      ).then((selection) => {
        const cardWithUpdatedSelection = {
          ...card,
          data: updateDataCardInnerImmut(card.data, "dataSelections", () => [
            { ...dataSelection, measureSelection: selection },
          ]),
        };
        const updatedCard = defined(settings)
          ? applyDefaultSettings(
              settings,
              appearanceSettings.defaultTheme.customOutputSettings,
              cardWithUpdatedSelection,
              geographies
            )
          : cardWithUpdatedSelection;

        handleAddCard(
          updatedCard,
          0,
          createColorSchemeContainerWithPalette(appearanceSettings.defaultTheme)
        );
      });
    },
    [
      adminShowDraftData,
      appearanceSettings.defaultTheme,
      geographies,
      getDefaultMeasure,
      handleAddCard,
    ]
  );

  return (
    <DataPreview handleClose={handleCloseModal}>
      <div className="home-search-container">
        <SearchMain
          className="home-search"
          placeholder="Sök statistik"
          autofocus
          onSelectMeasure={handleSelectMeasureResult}
          onSelectPath={handleSelectSubjectPath}
        ></SearchMain>
      </div>
    </DataPreview>
  );
}

export function PreviewSingleMeasure(props: {
  dataId: number;
  handleClose: (cardId: string) => void;
}) {
  const appMessages = useContext(AppMessagesContext);
  const geographies = useContext(GeographiesContext);

  const load = useLoadStatsMeasureAsNewCard(false);

  useEffect(() => {
    if (!defined(geographies)) {
      return;
    }

    const controller = new PromiseController();
    statsApi
      .getSingleMeasure(props.dataId, false, true)
      .then((res) => {
        if (controller.stopped) {
          return;
        }
        const measure = res.match({
          ok: (m) => m,
          err: (err) => {
            logger.error("Failed to load single measure", err);
            appMessages?.add("error", "Kunde inte ladda måttet");
            return undefined;
          },
        });
        if (!defined(measure)) {
          return;
        }
        const subjectPath = getSubjectPath(measure);
        return statsApi
          .getAvailableMeasures(
            subjectPath,
            false,
            false,
            false,
            TimeResolution.maximal()
          )
          .then((availableMeasures) => {
            if (controller.stopped) {
              return;
            }
            return load(
              measure.data_id,
              subjectPath,
              availableMeasures,
              geographies
            );
          });
      })
      .catch((err) => {
        logger.error("Failed to load single measure", err);
        appMessages?.add("error", "Kunde inte ladda måttet");
      });

    return () => {
      controller.stop();
    };
    // Ignore load - it will change after every call but we don't want to re-run this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMessages, geographies, props.dataId]);

  return <DataPreview handleClose={props.handleClose} />;
}

interface DataPreviewProps {
  /** For displaying the search bar */
  children?: JSX.Element | JSX.Element[];
  handleClose: (cardId: string) => void;
}

function DataPreview(props: DataPreviewProps) {
  const [saveAsModalOpen, setSaveAsModalOpen] = useState(false);
  const geographies = useContext(GeographiesContext);
  const cardsList = useRecoilValue(docCardsListQuery);
  const getCards = useGetAllCardsCallback(cardsList);
  const handleClose = props.handleClose;
  // NB: don't use the entire card here - the card content will not be reactively updated since we're not
  // using recoil values
  const cardId = getCards().find((c) => c.type === "dataCard")?.id;

  const handleCloseModal = useCallback(() => {
    if (!defined(cardId)) {
      return;
    }
    handleClose(cardId);
  }, [cardId, handleClose]);

  const handleClickSaveAsDoc = useCallback(() => {
    if (!defined(cardId)) {
      return;
    }
    setSaveAsModalOpen(true);
  }, [cardId]);

  useKeyUpEscape(() => {
    if (savedDocId) {
      setSavedDocId(undefined);
    } else if (saveAsModalOpen) {
      setSaveAsModalOpen(false);
    } else if (defined(cardId)) {
      handleCloseModal();
    }
  });

  const getAllCardsCallback = useGetAllCardsCallback(cardsList);

  const [saveProgress, setSaveProgress] = useState<Progress>(
    Progress.NotStarted
  );
  const [savedDocId, setSavedDocId] = useState<number | undefined>(undefined);

  const handleSaveAs = useCallback(
    (name: string) => {
      const allCards = getAllCardsCallback();
      const workspace = stateToWorkspaceLatest(allCards, { editModeOn: true });
      setSaveProgress(Progress.InProgress);
      return createDocument(name, workspace)
        .then((res) => {
          const docId = res.unwrap();
          setSavedDocId(docId);
          setSaveProgress(Progress.Success);
        })
        .catch(() => {
          setSaveProgress(Progress.Error);
        });
    },
    [getAllCardsCallback]
  );

  const handleDismissDocumentCreated = useCallback(() => {
    setSavedDocId(undefined);
    setSaveProgress(Progress.NotStarted);
  }, []);

  return (
    <>
      {saveAsModalOpen && (
        <SimpleInputModal
          title="Spara som dokument"
          onDismiss={() => setSaveAsModalOpen(false)}
          onSaveInput={(name) => {
            setSaveAsModalOpen(false);
            handleSaveAs(name);
          }}
          input={{
            initialValue: getText("default-document-name"),
            label: "Namn",
            maxLength: config.docTitleMaxLength,
            validate: validateNonEmptyString,
          }}
          autoSelectText={true}
        ></SimpleInputModal>
      )}
      {defined(savedDocId) && (
        <FluentModal
          title="Dokument skapat"
          isOpen={true}
          onClose={handleDismissDocumentCreated}
        >
          <FluentModalBody>
            <p>Ditt nya dokument är nu klart.</p>
          </FluentModalBody>
          <FluentModalFooter>
            <ButtonsFooter>
              <ButtonsFooterLeft>
                <Button
                  onClick={handleDismissDocumentCreated}
                  title="Stäng"
                ></Button>
              </ButtonsFooterLeft>
              <ButtonsFooterRight>
                <Button
                  title="Gå till dokument"
                  onClick={() => {
                    handleDismissDocumentCreated();
                    openDocument(savedDocId);
                  }}
                  intent="primary"
                ></Button>
              </ButtonsFooterRight>
            </ButtonsFooter>
          </FluentModalFooter>
        </FluentModal>
      )}
      <DataPreviewContext.Provider value={true}>
        {props.children}

        {defined(cardId) && defined(geographies) && (
          <div id="data-search-preview">
            <div className="data-container">
              <ButtonsFooter className="mobile-only padding-bottom-sm padding-top-sm">
                <ButtonsFooterLeft>
                  <Button title="Stäng" onClick={handleCloseModal}></Button>
                </ButtonsFooterLeft>
                <ButtonsFooterRight>
                  <Button
                    disabled={saveProgress === Progress.InProgress}
                    intent="primary"
                    title="Spara som dokument"
                    onClick={handleClickSaveAsDoc}
                  ></Button>
                </ButtonsFooterRight>
              </ButtonsFooter>
              <Card>
                <>
                  {saveProgress === Progress.InProgress && (
                    <div className="card-loading-indicator">
                      <DefaultLoading
                        spinnerSize={SpinnerSize.large}
                      ></DefaultLoading>
                    </div>
                  )}
                </>
                <DataCardContent
                  cardId={cardId}
                  geographies={geographies}
                  isEditing={true}
                ></DataCardContent>
              </Card>
              <ButtonsFooter className="hide-on-mobile padding-top-sm padding-bottom-md">
                <ButtonsFooterLeft>
                  <Button title="Stäng" onClick={handleCloseModal}></Button>
                </ButtonsFooterLeft>
                <ButtonsFooterRight>
                  <Button
                    disabled={saveProgress === Progress.InProgress}
                    intent="primary"
                    title="Spara som dokument"
                    onClick={handleClickSaveAsDoc}
                  ></Button>
                </ButtonsFooterRight>
              </ButtonsFooter>
            </div>
          </div>
        )}
      </DataPreviewContext.Provider>
    </>
  );
}
