import { Pivot, PivotItem } from "@fluentui/react";
import { useCallback, useContext, useEffect, useMemo } from "react";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useRecoilState } from "recoil";
import "mapbox-gl/dist/mapbox-gl.css";

import { defined } from "../../../../../lib/core/defined";
import { CardContainer } from "../CardContainer";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { singleMicroCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/microCard";
import {
  PageBreakAfter,
  PageBreakBefore,
} from "../../../../../components/print/page_breaks";
import {
  TabbedCardMain,
  TabbedCardTab,
  TabbedCardTabsContainer,
} from "../../../../../components/Card";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";
import { DocCardMicro } from "../../../../../lib/application/state/stats/document-core/core";
import { MeasureSelectionMicroThirdPartyDoc } from "./components/MeasureSelectionMicro";
import {
  MicroOutputTab,
  microSelectionPrimary,
  microSelectionsNonPrimary,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { classNames } from "../../../../../lib/core/classNames";
import { MicroMap } from "./components/MicroMap";
import { ChartTab } from "../card_general/ChartTab";
import { TableTab } from "../card_general/TableTab";
import {
  CardUpdateCountContext,
  CategoriesContextMicroPrimaryContext,
  CategoriesContextMicroSecondaryContext,
  MicroGeoTreeContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { Categories } from "../../../../../lib/domain/categories";
import { useChangeMeasure } from "../../../../../lib/application/state/actions/micro/useChangeMeasure";
import { useChangeGeotype } from "../../../../../lib/application/state/actions/micro/useChangeGeotype";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import { dataValueTypesMicroGeo } from "../../../../../lib/infra/api_responses/dataset";
import { useGeoTree } from "./useGeoTree";
import { MicroInfoTab } from "./components/MicroInfoTab";
import { ComputedMeasurementType } from "../../../../../lib/infra/api_responses/micro_dataset";
import {
  cardWithUpdatedDataOutputSettingsMicro,
  loadAndStoreDataMicro,
} from "../../../../../lib/application/state/stats/cardToDataStateMicro";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { useWrappedIncrementer } from "../../../../../lib/application/hooks/useIncrementer";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { Progress } from "../../../../../lib/core/progress";
import {
  DataLoadErrorView,
  DataLoadNotStartedView,
} from "../card_general/data_load_views";
import { validateMicroCardSelection } from "../../../../../lib/application/state/stats/document-core/card_info";
import { DataOutputToolbar } from "../card_general/DataOutputToolbar";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import { MicroMapDownloadEventProvider } from "../../../../../lib/application/events/MicroMapDownloadEventProvider";
import { useElementSize } from "../../../../../lib/application/hooks/useElementSize";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";
import { ThirdPartyMicroCardTabView } from "../card_tabs/MicroCardTab";

import "./MicroCard.scss";
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

const pivots: Array<{
  key: MicroOutputTab;
  title: string;
  component?: JSX.Element;
}> = [
  { key: "map-select", title: "Välj områden" },
  { key: "map-view", title: "Karta" },
  {
    key: "chart",
    title: "Diagram",
  },
  { key: "table", title: "Tabell" },
  { key: "info", title: "Info" },
];

interface Props {
  cardId: string;
  tempRenderInvisible: boolean;
  onRenderDone: () => void;
  sharingInfo: ISharingInfo;
}

export function MicroCardThirdPartyDoc(props: Props) {
  const updateCounter = useWrappedIncrementer();

  const categoriesPrimary = useContext(CategoriesContextMicroPrimaryContext);
  const categoriesSecondary = useContext(
    CategoriesContextMicroSecondaryContext
  );
  const geoTree = useGeoTree();

  if (!defined(categoriesPrimary) || !defined(categoriesSecondary)) {
    throw new Error("Categories not defined");
  }
  if (!defined(geoTree)) {
    return null;
  }

  return (
    <CardUpdateCountContext.Provider value={updateCounter}>
      <MicroGeoTreeContext.Provider value={geoTree}>
        <MicroCardInner
          {...props}
          categoriesPrimary={categoriesPrimary}
          categoriesSecondary={categoriesSecondary}
        />
      </MicroGeoTreeContext.Provider>
    </CardUpdateCountContext.Provider>
  );
}

const mainTabName = "micro-tab";

const EMPTY_ARRAY: any[] = [];

export function MicroCardInner(
  props: Props & {
    categoriesPrimary: Categories;
    categoriesSecondary: Categories;
  }
) {
  const {
    cardId,
    sharingInfo,
    onRenderDone,
    categoriesPrimary,
    categoriesSecondary,
  } = props;
  const { ref: cardContentRef, height } = useElementSize();

  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: props.cardId })
  );

  const adminShowDraftData = useContext(ShowDraftDataContext);

  const [handleChangeMeasure, changeMeasurePending] = useChangeMeasure(
    cardId,
    adminShowDraftData,
    EMPTY_ARRAY
  );

  const handleChangeGeotype = useChangeGeotype(cardId);

  const changePending = changeMeasurePending;

  const microOutputDisabled =
    !defined(card.data.dataSelections) || changePending;
  const { isEditingCard } = useCardEditMode(cardId, sharingInfo);

  const primarySelection = microSelectionPrimary(card);

  useEffect(() => {
    if (height === 0) {
      return;
    }

    const handle = setTimeout(() => {
      onRenderDone();
    }, 300);

    return () => {
      return clearTimeout(handle);
    };
  }, [height, onRenderDone]);

  return (
    <CardContainer
      isRemovingCard={false}
      cardId={props.cardId}
      removeBrokenCard={voidFunc}
      disableScrollIntoView={props.tempRenderInvisible}
      className={classNames(
        "infostat-micro",
        props.tempRenderInvisible ? "invisible-micro-card" : ""
      )}
      sharingInfo={props.sharingInfo}
    >
      <PageBreakBefore breakSetting={card.pageBreak}></PageBreakBefore>
      <TabbedCardTabsContainer currentTab={mainTabName}>
        <TabbedCardTab
          key="primary"
          selected={true}
          name={mainTabName}
          onSelect={voidFunc}
        >
          <ThirdPartyMicroCardTabView
            sharingInfo={props.sharingInfo}
            cardId={card.id}
          />
        </TabbedCardTab>
      </TabbedCardTabsContainer>
      <TabbedCardMain name={mainTabName}>
        <div
          ref={cardContentRef}
          className={classNames("micro-card-content", "third-party-doc")}
        >
          {card.data.dataSelections?.map((selection, index) => {
            const canBePrimary =
              !defined(primarySelection) || selection.type === "primary";
            const otherSelectedMeasures =
              card.data.dataSelections
                ?.filter((s, i) => i !== index)
                .map((s) => {
                  const id = s.measure?.id;
                  if (!defined(id)) {
                    return;
                  }
                  return [id, s.measure?.computed?.type] as [
                    number,
                    ComputedMeasurementType
                  ];
                })
                .filter(defined) ?? EMPTY_ARRAY;

            return (
              <MeasureSelectionMicroThirdPartyDoc
                autofill
                otherSelectedMeasures={
                  otherSelectedMeasures.length > 0
                    ? otherSelectedMeasures
                    : EMPTY_ARRAY // Use EMPTY_ARRAY to keep referential equality
                }
                acceptMeasureValueTypes={
                  canBePrimary ? EMPTY_ARRAY : dataValueTypesMicroGeo
                }
                key={selection.id}
                selection={selection}
                handleRemoveMeasure={voidFunc}
                handleChangeGeotype={handleChangeGeotype}
                categories={
                  canBePrimary ? categoriesPrimary : categoriesSecondary
                }
                handleChangeMeasure={handleChangeMeasure}
                handleChangePath={voidFunc}
                changePending={changePending}
                cardId={cardId}
              />
            );
          })}
          <MicroOutputContainer
            isEditingCard={isEditingCard}
            microOutputDisabled={microOutputDisabled}
            card={card}
            setCard={setCard}
            sharingInfo={props.sharingInfo}
          ></MicroOutputContainer>
        </div>
      </TabbedCardMain>
      <PageBreakAfter breakSetting={card.pageBreak}></PageBreakAfter>
    </CardContainer>
  );
}

export function MicroOutputContainer(props: {
  isEditingCard: boolean;
  microOutputDisabled: boolean;
  sharingInfo: ISharingInfo;
  card: DocCardMicro;
  setCard: (card: DocCardMicro) => void;
}) {
  const { microOutputDisabled, card, setCard } = props;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const [settings, setSettings] = useChangeDataOutputSettings(card.id);
  const thirdPartyDocSettings = card.data.thirdPartyMicroCardSettings;

  const geoTree = useContext(MicroGeoTreeContext);
  const appearanceSettings = useExtendedAppearanceSettings();

  const secondarySelections = microSelectionsNonPrimary(card);
  const primarySelection = microSelectionPrimary(card);

  const saveCard = useSaveCard();

  const handleSetPivot = useCallback(
    (pivot: MicroOutputTab) => {
      if (!defined(geoTree)) {
        return;
      }
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const updatedBaseCard = {
        ...card,
        data: {
          ...card.data,
          selectedTab: pivot,
        },
      };

      loadAndStoreDataMicro(
        updatedBaseCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedBaseCard),
        saveCard
      );
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      incrementUpdateCount,
      saveCard,
      setCard,
    ]
  );

  const handlePivotClick = useCallback(
    (item?: PivotItem) => {
      const key = item?.props.itemKey;
      if (!defined(item) || !defined(key)) {
        return;
      }
      handleSetPivot(key as any);
    },
    [handleSetPivot]
  );

  const selections = card.data.geoSelections;
  const hasGeoSelections =
    defined(selections) && selections.selected.length > 0;

  const supportedPivots = useMemo(() => {
    return pivots.filter((p) => {
      switch (p.key) {
        case "map-select":
          return false; // Never available for third party docs
        case "map-view":
          return (
            !thirdPartyDocSettings?.hideTabs?.includes("map-view") &&
            ((defined(primarySelection) && hasGeoSelections) ||
              secondarySelections.length > 0)
          );
        case "chart":
          return (
            !thirdPartyDocSettings?.hideTabs?.includes("chart") &&
            defined(primarySelection) &&
            hasGeoSelections
          );
        case "table":
          return (
            !thirdPartyDocSettings?.hideTabs?.includes("table") &&
            defined(primarySelection) &&
            hasGeoSelections
          );
        case "info":
          return (
            !thirdPartyDocSettings?.hideTabs?.includes("info") &&
            (defined(primarySelection) || secondarySelections.length > 0)
          );
      }
    });
  }, [
    hasGeoSelections,
    primarySelection,
    secondarySelections.length,
    thirdPartyDocSettings?.hideTabs,
  ]);

  const currentPivot = supportedPivots.find(
    (p) => p.key === card.data.selectedTab
  );

  useEffect(() => {
    if (!defined(currentPivot)) {
      const firstSupportedPivot = supportedPivots[0];
      if (defined(firstSupportedPivot)) {
        handleSetPivot(firstSupportedPivot.key);
      }
    }
  }, [currentPivot, handleSetPivot, supportedPivots]);

  const thirdPartyDocNoControlsAboveToolbar = useMemo(() => {
    const thirdPartySettings = card.data.thirdPartyMicroCardSettings;
    return (
      !thirdPartySettings?.showTimeline &&
      !thirdPartySettings?.showBreakdowns &&
      card.data.filterMeasures.length === 0
    );
  }, [card.data.filterMeasures.length, card.data.thirdPartyMicroCardSettings]);

  return (
    <MicroMapDownloadEventProvider>
      <div className={classNames("micro-output-container")}>
        {microOutputDisabled && (
          <div className="micro-viewer-disabled-overlay"></div>
        )}
        <div
          className={classNames(
            "micro-output-viewer",
            ["map-select", "map-view"].includes(currentPivot?.key ?? "")
              ? "map-output"
              : ""
          )}
        >
          <div
            className={classNames(
              "menu-container",
              "menu-container-micro",
              "tools-surface",
              thirdPartyDocNoControlsAboveToolbar
                ? "no-controls-above-toolbar"
                : ""
            )}
          >
            {supportedPivots.length > 1 && (
              <Pivot
                className="pivot-menu"
                selectedKey={card.data.selectedTab}
                onLinkClick={handlePivotClick}
                getTabId={(key) => key}
              >
                {supportedPivots.map((p) => (
                  <PivotItem
                    key={p.title}
                    itemKey={p.key}
                    headerText={p.title}
                  ></PivotItem>
                ))}
              </Pivot>
            )}
            <DataOutputToolbar
              isThirdPartyDoc={true}
              cardRaw={card}
              isEditing={true}
              settings={settings}
              setSettings={setSettings}
              showToolbar={thirdPartyDocSettings?.showToolbar}
              showToolbarDownload={thirdPartyDocSettings?.showToolbarDownload}
              showToolbarSettings={thirdPartyDocSettings?.showToolbarSettings}
            />
          </div>
          <div className={classNames("tab-content-with-toolbar")}>
            <TabContent
              cardStateId={card.id}
              selectedPivot={card.data.selectedTab}
              sharingInfo={props.sharingInfo}
            ></TabContent>
          </div>
        </div>
      </div>
    </MicroMapDownloadEventProvider>
  );
}

function TabContent(props: {
  cardStateId: string;
  selectedPivot?: MicroOutputTab;
  sharingInfo: ISharingInfo;
}) {
  const { cardStateId, selectedPivot, sharingInfo } = props;

  const [card, setCard] = useRecoilState(singleMicroCardQuery({ cardStateId }));
  const primarySelection = microSelectionPrimary(card);
  const nonPrimarySelections = microSelectionsNonPrimary(card);

  const saveCard = useSaveCard();
  const extendedAppearanceSettings = useExtendedAppearanceSettings();

  const handleUpdateSettings = useCallback(
    async (newSettings: DataOutputSettings) => {
      const updatedCard = await cardWithUpdatedDataOutputSettingsMicro(
        card,
        extendedAppearanceSettings,
        newSettings
      );
      if (!defined(updatedCard)) {
        return;
      }
      setCard(updatedCard);
      saveCard?.(updatedCard);
    },
    [card, extendedAppearanceSettings, saveCard, setCard]
  );

  const loadedData = card.data.loadedData;

  const dataPivots: MicroOutputTab[] = ["chart", "table"];
  if (defined(selectedPivot) && dataPivots.includes(selectedPivot)) {
    if (loadedData?.primaryProgress?.type === Progress.NotStarted) {
      return (
        <DataLoadNotStartedView
          info={loadedData.primaryProgress.info}
        ></DataLoadNotStartedView>
      );
    }
    const validationError = validateMicroCardSelection(card);
    if (defined(validationError)) {
      return (
        <DataLoadNotStartedView
          info={{ type: "selections-incomplete", incomplete: validationError }}
        />
      );
    }
  }

  if (
    defined(selectedPivot) &&
    dataPivots.includes(selectedPivot) &&
    loadedData?.primaryProgress?.type === Progress.Error
  ) {
    return (
      <DataLoadErrorView
        error={loadedData.primaryProgress.error}
      ></DataLoadErrorView>
    );
  }

  return (
    <>
      {(selectedPivot === "map-view" || selectedPivot === "map-select") && (
        <MicroMap
          view={selectedPivot}
          cardId={cardStateId}
          sharingInfo={sharingInfo}
        ></MicroMap>
      )}
      {selectedPivot === "chart" && (
        <ChartTab
          chartDataState={loadedData?.chartDataState}
          cardId={cardStateId}
        ></ChartTab>
      )}
      {selectedPivot === "table" && (
        <TableTab
          tableDataState={loadedData?.tableDataState}
          settings={card.data.settings.dataOutputSettings}
          setSettings={handleUpdateSettings}
        />
      )}
      {selectedPivot === "info" && (
        <MicroInfoTab
          primarySelection={primarySelection}
          nonPrimarySelectionsPartial={nonPrimarySelections}
        ></MicroInfoTab>
      )}
    </>
  );
}
