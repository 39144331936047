import { config } from "../../../config";
import {
  MyOrganizationDto,
  MyOrganizationDtoRT,
  UserInfoDto,
  UserInfoDtoRT,
} from "../../infra/api_responses/account";
import { HttpResult } from "../../infra/HttpResult";
import { authedRequest, decodedAuthedRequest } from "./shared";

export function getAccountInfo(): Promise<HttpResult<UserInfoDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "users/me",
    undefined,
    "GET",
    UserInfoDtoRT
  );
}

export function setUserTutorialSeen(
  tutorialKey: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/seentutorials/`,
    { tutorial: tutorialKey },
    "POST"
  );
}

export function updateUserPreferences(
  properties: Partial<UserInfoDto["preferences"]>
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "users/me/preferences",
    properties,
    "PATCH"
  );
}

export function updateOrgPreferences(
  properties: Partial<UserInfoDto["organization_preferences"]>
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "users/me/organization/preferences",
    properties,
    "PATCH"
  );
}

export function getMyOrganization(): Promise<HttpResult<MyOrganizationDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "users/me/organization",
    undefined,
    "GET",
    MyOrganizationDtoRT
  );
}
