import { ContextualMenu, Icon } from "@fluentui/react";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { SimpleInputModal } from "../../../components/SimpleInputModal";
import { config } from "../../../config";
import { validateNonEmptyString } from "../../../lib/application/validation/string";
import { getText } from "../../../lib/application/strings";
import { classNames } from "../../../lib/core/classNames";
import { defined } from "../../../lib/core/defined";
import { Categories } from "../../../lib/domain/categories";
import { newDocPath, statsOverviewPath } from "../../../lib/paths";
import "./Toolbar.scss";
import { ShareDialog } from "../sharing/ShareDialog";
import { RearrangeDialog } from "./RearrangeDialog";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import {
  AppMessagesContext,
  SaveDocumentContext,
  SharingInfoContext,
  TutorialsContext,
  UserInfoContext,
} from "../../../lib/application/contexts";
import { useDocumentMetadataValue } from "../../../lib/application/state/stats/useMetadata";
import { useDocumentEditMode } from "../../../lib/application/state/stats/useEditMode";
import { logger } from "../../../lib/infra/logging";
import { AlertRegistrations } from "../../../lib/domain/alerts";
import {
  createDocumentAlertRegistration,
  deleteAlertRegistration,
} from "../../../lib/application/requests/common_requests";
import { SubscriptionInfoDialog } from "./SubscriptionInfoDialog";
import { IconBuildingCommunity } from "@tabler/icons-react";
import {
  reportEditModeOnQuery,
  reportMetaStateQuery,
} from "../../../lib/application/state/stats/document-meta/queries";
import { useGetAllCardsCallback } from "../../../lib/application/state/actions/cardCallbacks";
import { Permission } from "../../../lib/application/auth/UserInfo";
import { TutorialEvents } from "../../../lib/application/tutorial_system/tutorials_handler";

interface Props {
  categories: Categories;
  userId: string | undefined;
  alertRegistrations: null | AlertRegistrations;
  reloadAlertRegistrations: () => void;
  handleAddDataCard: () => void;
  handleAddNewTextCardCK: () => void;
  handleAddMicroCard: () => void;
  handleAddPythonCard: () => void;
  handleSaveAs: (name: string) => void;
}

export function Toolbar(props: Props) {
  const cardsList = useRecoilValue(docCardsListQuery);
  const setEditModeOn = useSetRecoilState(reportEditModeOnQuery);
  const [shareDocDialogOpen, setShareDocDialogOpen] = React.useState(false);
  const [showRearrange, setShowRearrange] = React.useState(false);
  const [saveAsModalOpen, setSaveAsModalOpen] = React.useState(false);
  const [showSubscriptionsInfo, setShowSubscriptionsInfo] =
    React.useState(false);
  const [editAsOwnDocumentDialogOpen, setEditAsOwnDocumentDialogOpen] =
    useState(false);

  const navigate = useNavigate();
  const userInfo = useContext(UserInfoContext);
  const sharingInfoContext = useContext(SharingInfoContext);
  const documentMetadata = useDocumentMetadataValue();
  const metaState = useRecoilValue(reportMetaStateQuery);
  const appMessages = useContext(AppMessagesContext);

  const lockedByOtherUser = documentMetadata?.lockedByOther() === true;

  const handleSaveDoc = useContext(SaveDocumentContext);
  const getAllCards = useGetAllCardsCallback(cardsList);

  const handleSetEditMode = useCallback(
    (value: boolean) => {
      const cards = getAllCards();
      setEditModeOn(value);
      handleSaveDoc?.(cards, {
        editModeOn: value,
        noFramesMode: metaState?.noFramesMode ?? false,
        thirdPartySharingDoc: metaState?.thirdPartySharingDoc,
      });
    },
    [
      getAllCards,
      handleSaveDoc,
      metaState?.noFramesMode,
      metaState?.thirdPartySharingDoc,
      setEditModeOn,
    ]
  );

  const handlePrint = useCallback(() => {
    try {
      window.print();
    } catch (e) {
      logger.error("Failed to activate print mode", e);
    }
  }, []);

  const { tutorialsHandler, availableTutorials } = useContext(TutorialsContext);

  useLayoutEffect(() => {
    if (!defined(userInfo)) {
      return;
    }
    if (!defined(tutorialsHandler)) {
      return;
    }

    if (
      // If tutorial has been seen, don't show it automatically
      tutorialsHandler.seenTutorials().includes("stats_card_intro") ||
      // Show for empty documents only
      cardsList.length > 0 ||
      // Only run if the tutorial is available. It won't be available if the user is not in editing mode.
      availableTutorials.every((t) => t.key !== "stats_card_intro")
    ) {
      return;
    }

    if (lockedByOtherUser || !metaState?.editModeOn) {
      return;
    }

    tutorialsHandler?.start("stats_card_intro");
  }, [
    availableTutorials,
    cardsList.length,
    lockedByOtherUser,
    metaState?.editModeOn,
    tutorialsHandler,
    userInfo,
  ]);

  const canWriteAllCards = useMemo(() => {
    return metaState?.userHasApplicableWriteLicenses ?? false;
  }, [metaState?.userHasApplicableWriteLicenses]);

  const isReadOnly = useMemo(() => {
    if (!defined(sharingInfoContext)) {
      return true;
    }

    if (!defined(userInfo)) {
      return true;
    }

    return sharingInfoContext.info.canViewOnly() || !canWriteAllCards;
  }, [canWriteAllCards, sharingInfoContext, userInfo]);

  if (!defined(sharingInfoContext)) {
    throw new Error("SharingInfoContext is not defined -- must not happen");
  }

  const { isEditingDocument } = useDocumentEditMode(
    sharingInfoContext.info,
    documentMetadata
  );

  const numCards = cardsList.length;

  const createNewDocument = () => {
    window.open(newDocPath(getText("default-document-name")));
  };

  const leftItems: ButtonProps[] = [
    {
      name: "Arkiv",
      icon: "folder-open",
      iconClassName: "folder",
      dropdown: [
        {
          name: "Nytt",
          icon: "document",
          onClick: () => {
            createNewDocument();
          },
        },
        {
          name: "Mina dokument",
          icon: "folder-open",
          onClick: () => {
            navigate(statsOverviewPath());
          },
        },
        {
          name: "Spara som ...",
          icon: "floppy-disk",
          onClick: () => {
            setSaveAsModalOpen(true);
          },
        },
      ],
    },
  ];

  if (isEditingDocument) {
    const dropdownItems: DropdownItem[] = [];
    if (userInfo?.hasStatsWriteAccess()) {
      dropdownItems.push({
        icon: "series-add",
        name: "Stats",
        onClick: () => props.handleAddDataCard(),
      });
    }
    if (userInfo?.hasMicroWriteAccess()) {
      dropdownItems.push({
        icon: <IconBuildingCommunity size={18}></IconBuildingCommunity>,
        name: "Mikro",
        onClick: () => props.handleAddMicroCard(),
      });
    }
    if (userInfo?.hasPermission(Permission.PythonAnalysis)) {
      dropdownItems.push({
        icon: "code",
        name: "Makro",
        onClick: () => props.handleAddPythonCard(),
      });
    }
    if (userInfo?.hasAnyWriteAccess()) {
      dropdownItems.push({
        icon: "new-text-box",
        name: "Text",
        onClick: () => {
          props.handleAddNewTextCardCK();
        },
      });
    }

    if (dropdownItems.length > 0) {
      leftItems.push({
        name: "Nytt kort",
        icon: "add",
        dropdown: dropdownItems,
        dropdownClassName: "new-card-dropdown",
        onClick: () => {
          tutorialsHandler?.handleEvent(TutorialEvents.NewCardMenuClicked);
        },
        classNames: "new-card",
        iconClassName: "new-card",
      });
    }

    leftItems.push({
      name: "Ordna",
      disabled: numCards <= 1,
      icon: "numbered-list",
      iconClassName: "rearrange",
      onClick: () => setShowRearrange(true),
    });
  }

  const editProps = {
    name:
      "Redigering" + (lockedByOtherUser ? " (låst av annan användare)" : ""),
    icon: "edit",
    disabled: lockedByOtherUser,
  };

  const viewProps = { name: "Visning", icon: "eye-open" };
  const editModeProps = isEditingDocument ? editProps : viewProps;
  const rightItems: ButtonProps[] = [];
  if (isReadOnly) {
    rightItems.push({
      name: "Redigera",
      disabled: !canWriteAllCards,
      icon: "edit",
      onClick: () => {
        setEditAsOwnDocumentDialogOpen(true);
      },
    });
  } else {
    // Not read-only
    rightItems.push({
      name: "Dela dokument",
      icon: "document-share",
      classNames: "share-button",
      iconClassName: "share",
      onClick: () => setShareDocDialogOpen(true),
    });
  }

  const alertRegistrations = props.alertRegistrations;
  if (
    !isEditingDocument &&
    alertRegistrations !== null &&
    (sharingInfoContext.info.isHelpLibDoc() ||
      sharingInfoContext.info.isSampleLibDoc())
  ) {
    const documentRegistration = defined(documentMetadata)
      ? alertRegistrations.documentRegistration(documentMetadata.id)
      : undefined;
    const hasRegistration = defined(documentRegistration);
    rightItems.push({
      name: "Bevaka " + (hasRegistration ? "(på)" : "(av)"),
      iconClassName: hasRegistration ? "alerts-active" : "alerts-inactive",
      icon: hasRegistration ? "notifications-updated" : "notifications",
      dropdown: [
        {
          name: "Av",
          disabled: !hasRegistration,
          iconClassName: "alerts-inactive",
          icon: "notifications",
          onClick: () => {
            if (defined(props.userId) && defined(documentRegistration)) {
              deleteAlertRegistration(
                props.userId,
                documentRegistration.id
              ).then((res) => {
                res.match({
                  ok: () => {
                    props.reloadAlertRegistrations();
                  },
                  err: (e) => {
                    logger.error(e);
                    appMessages?.add("error", "Kunde inte ta bort bevakning");
                  },
                });
              });
            }
          },
        },
        {
          name: "På",
          disabled: hasRegistration,
          icon: "notifications-updated",
          onClick: () => {
            const docId = documentMetadata?.id;
            if (defined(props.userId) && defined(docId)) {
              createDocumentAlertRegistration(props.userId, docId).then(
                (res) => {
                  res.match({
                    ok: () => {
                      props.reloadAlertRegistrations();
                    },
                    err: (e) => {
                      logger.error(e);
                      appMessages?.add(
                        "error",
                        "Kunde inte lägga till bevakning"
                      );
                    },
                  });
                }
              );
            }
          },
        },
        {
          name: "Vad är bevakningar?",
          icon: "info",
          onClick: () => setShowSubscriptionsInfo(true),
        },
      ],
    });
  }

  if (!isEditingDocument) {
    rightItems.push({ name: "Skriv ut", icon: "print", onClick: handlePrint });
  }

  if (!isReadOnly) {
    rightItems.push({
      ...editModeProps,
      disabled: isReadOnly,
      dropdown: [
        {
          ...editProps,
          onClick: () =>
            !isEditingDocument ? handleSetEditMode(true) : undefined,
        },
        {
          ...viewProps,
          onClick: () =>
            isEditingDocument ? handleSetEditMode(false) : undefined,
        },
      ].filter(defined),
    });
  }

  const defaultCopyTitle = defined(documentMetadata)
    ? documentMetadata?.title + " – kopia"
    : getText("default-document-name");

  return (
    <div id="stats-toolbar">
      {showSubscriptionsInfo && (
        <SubscriptionInfoDialog
          onClose={() => setShowSubscriptionsInfo(false)}
        ></SubscriptionInfoDialog>
      )}
      {editAsOwnDocumentDialogOpen && (
        <SimpleInputModal
          title="Redigera egen kopia"
          description="Om du vill göra ändringar behöver du först göra en egen kopia. Originalet kan inte ändras. Ange ett namn på kopian nedan."
          onDismiss={() => setEditAsOwnDocumentDialogOpen(false)}
          onSaveInput={(name) => {
            setEditAsOwnDocumentDialogOpen(false);
            props.handleSaveAs(name);
          }}
          actions={{ saveLabel: "Skapa" }}
          input={{
            initialValue: defaultCopyTitle,
            label: "Namn",
            maxLength: config.docTitleMaxLength,
            validate: validateNonEmptyString,
          }}
          autoSelectText={true}
        ></SimpleInputModal>
      )}
      {shareDocDialogOpen && defined(documentMetadata) && (
        <ShareDialog
          nodeTitle={documentMetadata.title}
          nodeType="document" // From the Toolbar we can only share the current document
          sharingInfo={sharingInfoContext.info}
          reloadSharingInfo={sharingInfoContext.reload}
          nodeId={documentMetadata.id}
          onClose={() => setShareDocDialogOpen(false)}
        ></ShareDialog>
      )}
      {showRearrange && (
        <RearrangeDialog
          onClose={() => setShowRearrange(false)}
        ></RearrangeDialog>
      )}
      {saveAsModalOpen && (
        <SimpleInputModal
          title="Spara som egen kopia"
          onDismiss={() => setSaveAsModalOpen(false)}
          onSaveInput={(name) => {
            setSaveAsModalOpen(false);
            props.handleSaveAs(name);
          }}
          input={{
            initialValue: defaultCopyTitle,
            label: "Namn",
            maxLength: config.docTitleMaxLength,
            validate: validateNonEmptyString,
          }}
          autoSelectText={true}
        ></SimpleInputModal>
      )}
      <div className="toolbar-inner">
        <div className="toolbar-group">
          {leftItems.map((item) => (
            <BigButton key={item.name} {...item}></BigButton>
          ))}
        </div>
        <div className="toolbar-group">
          {rightItems.map((item) => (
            <BigButton key={item.name} {...item}></BigButton>
          ))}
        </div>
      </div>
    </div>
  );
}

interface DropdownItem {
  name: string;
  icon: string | React.ReactNode;
  iconClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
}
interface ButtonProps {
  icon: string;
  name: string;
  disabled?: boolean;
  iconClassName?: string;
  onClick?: () => void;
  dropdown?: DropdownItem[];
  dropdownClassName?: string;
  classNames?: string;
}
function BigButton(props: ButtonProps) {
  const { icon, name, dropdown, onClick, iconClassName } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const contextMenuContainerRef = React.useRef(null);

  const { tutorialsHandler } = useContext(TutorialsContext);

  return (
    <div
      key={name}
      className={classNames(
        "toolbar-item",
        defined(dropdown) ? "has-dropdown" : ""
      )}
    >
      <div
        className={classNames(
          "big-button",
          props.classNames,
          props.disabled ? "disabled" : ""
        )}
        ref={contextMenuContainerRef}
        onClick={
          props.disabled
            ? undefined
            : () => {
                if (defined(dropdown)) {
                  setIsOpen(!isOpen);
                }
                onClick?.();
              }
        }
      >
        <div className="button-main">
          <div
            className={classNames(
              "icon-container",
              defined(iconClassName) ? "icon-styled " + iconClassName : ""
            )}
          >
            <Icon iconName={icon} />
          </div>
          <div className="label-container">
            <span>{name}</span>
          </div>
        </div>
        {defined(dropdown) && (
          <div className="button-side-indicator">
            <Icon iconName="caret-down" />
          </div>
        )}
        {defined(dropdown) && (
          <ContextualMenu
            className={classNames(
              "infostat-toolbar-dropdown-menu",
              props.dropdownClassName
            )}
            hidden={!isOpen}
            items={dropdown.map((d) => {
              if (typeof d.icon === "string") {
                return {
                  key: d.name,
                  text: d.name,
                  onClick: d.onClick,
                  disabled: d.disabled,
                  iconProps: { className: d.iconClassName, iconName: d.icon },
                };
              }
              return {
                key: d.name,
                text: d.name,
                onClick: d.onClick,
                disabled: d.disabled,
                onRenderContent: () => {
                  return (
                    <div className="context-menu-item-with-custom-icon">
                      <div className="custom-icon">{d.icon}</div>
                      <div>{d.name}</div>
                    </div>
                  );
                },
              };
            })}
            target={contextMenuContainerRef}
            onDismiss={(ev) => {
              // Avoid automatically closing the dropdown when tutorial step is highlighted
              if (tutorialsHandler?.isActive() && ev?.type === "focus") {
                return;
              }
              setIsOpen(false);
            }}
          ></ContextualMenu>
        )}
      </div>
    </div>
  );
}
