import {
  Array as ArrayRT,
  Record,
  Number as NumberRT,
  String as StringRT,
  Boolean as BooleanRT,
  Static,
  Null,
  Optional,
  Dictionary,
} from "runtypes";

export const AutoImportSpecDtoRT = Record({
  auto_import_spec_id: NumberRT,

  data_label: StringRT,
  group_label: StringRT,
  created_at: StringRT,
  updated_at: StringRT,
  last_completed_at: Optional(StringRT).Or(Null),
  last_failed_at: Optional(StringRT).Or(Null),

  last_updated_by_email: StringRT,
  priority: NumberRT,
  disabled: BooleanRT,
  next_start_time: StringRT,
});
export const AutoImportSpecsListDtoRT = ArrayRT(AutoImportSpecDtoRT).Or(Null);
export type AutoImportSpecDto = Static<typeof AutoImportSpecDtoRT>;

export const AutoImportLogDtoRT = Record({
  auto_import_log_id: NumberRT,
  started_at: StringRT,
  completed_at: Optional(StringRT),
  failed_at: Optional(StringRT),
  status: StringRT,
  log: StringRT,
  data_hash: StringRT,
});
export type AutoImportLogDto = Static<typeof AutoImportLogDtoRT>;
export const AutoImportLogListDtoRT = ArrayRT(AutoImportLogDtoRT).Or(Null);

export const AutoImportGroupDtoRT = Record({
  auto_import_group_id: NumberRT,
  label: StringRT,
});
export const AutoImportGroupListDtoRT = ArrayRT(AutoImportGroupDtoRT);
export type AutoImportGroupDto = Static<typeof AutoImportGroupDtoRT>;

export const AutoImportScheduleDtoRT = Record({
  auto_import_schedule_id: NumberRT,
  description: StringRT,
  label: StringRT,
  scheduled_time: StringRT,
  scheduled_days: ArrayRT(NumberRT),
  scheduled_months: ArrayRT(NumberRT),
});
export const AutoImportScheduleListDtoRT = ArrayRT(AutoImportScheduleDtoRT);
export type AutoImportScheduleDto = Static<typeof AutoImportScheduleDtoRT>;

export const ImportOverviewDtoRT = Record({
  time: StringRT,
  label: StringRT,
  value_type: StringRT,
  event: StringRT,
  message: Optional(StringRT).Or(Null),
  performed_by: StringRT,
});

export type ImportOverviewDto = ReturnType<typeof ImportOverviewDtoRT.check>;
export const ImportOverviewListDtoRT = ArrayRT(ImportOverviewDtoRT);

export const SingleAutoImportSpecDtoRT = Record({
  group: Record({
    auto_import_group_id: NumberRT,
    label: StringRT,
    description: StringRT,
  }),
  schedule: Record({
    auto_import_schedule_id: NumberRT,
    label: StringRT,
    description: StringRT,
    scheduled_time: StringRT,
    scheduled_days: ArrayRT(NumberRT),
    scheduled_months: ArrayRT(NumberRT),
  }),
  spec: Record({
    auto_import_spec_id: NumberRT,
    auto_import_group_id: NumberRT,
    auto_import_schedule_id: NumberRT,
    override_scheduled_times: Optional(ArrayRT(StringRT)),
    override_scheduled_days: Optional(ArrayRT(NumberRT)),
    override_scheduled_months: Optional(ArrayRT(NumberRT)),
    notification_recipients: Optional(ArrayRT(StringRT)),
    data_id: NumberRT,
    data_label: StringRT,
    created_at: StringRT,
    updated_at: StringRT,
    last_updated_by: StringRT,
    last_updated_by_email: StringRT,
    priority: NumberRT,
    disabled: BooleanRT,
    next_start_time: StringRT,
  }),
  latest_log: Null.Or(
    Record({
      auto_import_log_id: NumberRT,
      auto_import_spec_id: NumberRT,
      started_at: StringRT,
      completed_at: Optional(StringRT),
      failed_at: Optional(StringRT),
      status: StringRT,
      log: StringRT,
      data_hash: StringRT,
    })
  ),
  script: Record({
    auto_import_script_id: NumberRT,
    auto_import_spec_id: NumberRT,
    script: StringRT,
    created_at: StringRT,
    created_by: StringRT,
    created_by_email: StringRT,
  }),
});
export type SingleAutoImportSpecDto = Static<typeof SingleAutoImportSpecDtoRT>;

export const AutoImportScriptDocsDtoRT = Dictionary(StringRT, StringRT);
export type AutoImportScriptDocsDto = Static<typeof AutoImportScriptDocsDtoRT>;
