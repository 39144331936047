import { DeltaStatic } from "quill";
import { cloneDeep } from "lodash";
import { assertNever } from "@fluentui/react";
import {
  Array as ArrayRT,
  Static,
  Dictionary,
  String as StringRT,
  Union,
  Literal,
} from "runtypes";

import {
  GeoType,
  GeoTypeRT,
  RegionResponseItem,
  RegionResponseItemRT,
} from "../../../../domain/geography";
import {
  DataSelection,
  DataSelectionGrouping,
  DataSelectionWithoutId,
  MeasureSelection,
} from "../../../../domain/selections/definitions";
import { config } from "../../../../../config";
import {
  DateRangeRaw,
  MeasureSelectionGrouping,
} from "../../../../domain/measure/definitions";
import {
  InnerMicroCardData,
  microSelectionPrimary,
  DataSelectionMicro,
} from "./core-micro";
import { DataCardLoadedData } from "./_core-shared";
import { SurveyDataset } from "../../../stats/datasets/SurveyDataset";
import { StatsDataset } from "../../../stats/datasets/StatsDataset";
import { SurveyStringDataset } from "../../../stats/datasets/SurveyStringDataset";
import { Progress } from "../../../../core/progress";
import { CardSpaceSettings } from "../../actions/cardCallbacks";
import { ThirdPartyDocDataCardSettings } from "../../../third_party_docs";
import { DataOutputSettings } from "./DataOutputSettings";
import { defined } from "../../../../core/defined";

export type CardIdParams = {
  cardStateId: string;
};

export const useSingleColorSymbol = Symbol("useSingleColor");
export const ColorSchemeRT = Dictionary(StringRT, StringRT);
/**  domain (label) -> range (color) */
export type ColorScheme = Static<typeof ColorSchemeRT> & {
  [key: symbol]: boolean | undefined;
};

export const PageBreakRT = Union(
  Literal("none"),
  Literal("before"),
  Literal("after"),
  Literal("both")
);
export type PageBreakType = Static<typeof PageBreakRT>;

export const GeoSelectionsRT = Dictionary(
  ArrayRT(RegionResponseItemRT),
  GeoTypeRT
);
export type SingleGeoTypeSelection = {
  type: GeoType;
  items: RegionResponseItem[];
};

/**
 * GeoType -> RegionResponseItem[]
 */
export type GeoSelections = Static<typeof GeoSelectionsRT>;
export const DataOutputViewRT = Union(
  Literal("diagram"),
  Literal("table"),
  Literal("map"),
  Literal("info")
);
export type DataOutputView = Static<typeof DataOutputViewRT>;
export const defaultDataOutputView: DataOutputView = "diagram";

export const MICRO_REFERENCE_TYPES = [
  "showCountryReference",
  "showSelectedAreasAverage",
  "showMunicipalityReference",
  "showNuts3Reference",
] as const;
export type MicroReferenceType = (typeof MICRO_REFERENCE_TYPES)[number];

export type GeoExpansions = string[];
export interface CommonCardProps {
  id: string;
  isEditing: boolean;
  label: string;
  /** Hide space before this card */
  hideSpaceBefore?: boolean;
  /** Hide space after this card */
  hideSpaceAfter?: boolean;

  /**
   * Specifies whether to insert page breaks in relation to this card,
   * in print mode
   */
  pageBreak?: PageBreakType;
}

export interface InnerDataCardState {
  loadedData?: DataCardLoadedData<
    SurveyDataset | SurveyStringDataset | StatsDataset
  >;

  thirdPartyDataCardSettings?: ThirdPartyDocDataCardSettings;

  settings: DataOutputSettings;
  selectedView?: DataOutputView;
  dataSelections: DataSelection[];
  groupingSelection?: DataSelectionGrouping;

  /**
   * Undefined before any user selection has been done,
   * array of strings otherwise
   */
  geoSelections?: GeoSelections;

  /**
   * Geo selections are based on selections made for a
   * previously selected measure, and may not be valid for
   * the current measure.
   */
  geoSelectionsInherited: boolean;

  geoExpansions: GeoExpansions;
  timeSelection?: DateRangeRaw;
  lockToLatestTime?: boolean;
}

export type DataframeColumn =
  | DataframeStatsColumn
  | DataframeMicroColumn
  | DataframeUserDefinedColumn;

export interface DataframeUserDefinedColumn {
  type: "user-defined";
  columnName: string;
  expression: string;
}
export interface DataframeStatsColumn {
  type: "stats";
  columnName: string;
  selection: DataSelectionWithoutId;
  geoSelections: GeoSelections;
  timeSelection: DateRangeRaw;
  lockToLatestTime?: boolean;
}
export interface DataframeMicroColumn {
  type: "micro";
  columnName: string;
  selection: DataSelectionMicro;
  timeSelection: DateRangeRaw;
  lockToLatestTime?: boolean;
}

export const PythonCardSelectedViewRT = Union(
  Literal("create-dataframe"),
  Literal("execute-code")
);
export type PythonCardSelectedView = Static<typeof PythonCardSelectedViewRT>;

export interface InnerPythonCardState {
  selectedView: PythonCardSelectedView;
  surveySelection?: {
    selection: DataSelectionWithoutId;
    timeSelection: DateRangeRaw;
  };
  columns: DataframeColumn[];
  pythonCode?: string;
}

type CardStateWithLoadStatus<cardType, loadedDataType> = {
  type: cardType;
  data: loadedDataType;
} & (
  | {
      initState: Progress.NotStarted | Progress.InProgress;
    }
  | {
      initState: Progress.Error;
      error: string;
    }
  | {
      initState: Progress.Success;
    }
);

export type DocCardPython = CardStateWithLoadStatus<
  "pythonCard",
  InnerPythonCardState
> &
  CommonCardProps;

export type DocCardStats = CardStateWithLoadStatus<
  "dataCard",
  InnerDataCardState
> &
  CommonCardProps;

export type DocCardMicro = CardStateWithLoadStatus<
  "microCard",
  InnerMicroCardData
> &
  CommonCardProps;

/** Card type used for packaged docs only -- static image of a micro card */
export type DocCardMicroImage = {
  type: "microCardImage";
  imgUrl: string;
} & CommonCardProps;

export type QuillDocState = Pick<DeltaStatic, "ops">;

export type DocCardText = {
  type: "textCardSimple";
  data?: QuillDocState;
} & CommonCardProps;

export type DocCardTextCK = {
  type: "textCardCK";
  data: string;
} & CommonCardProps;

export type DocCardError = {
  id: string;
  type: "error";
  message: string;
};
export type DocCardStateNonError =
  | DocCardStats
  | DocCardText
  | DocCardTextCK
  | DocCardMicro
  | DocCardMicroImage
  | DocCardPython;
export type DocCardState = DocCardStateNonError | DocCardError;
export type CardType = DocCardState["type"];

let selectionIdCounter = 1;
let cardIdCounter = 1;

export function makeSelectionId(): string {
  return (selectionIdCounter++).toString();
}

export function makeCardId(): string {
  const maxIters = config.maxNumDocCards;
  while (cardIdCounter <= cardIdCounter + maxIters) {
    const id = cardIdCounter.toString();
    cardIdCounter += 1;
    if (!usedIds.has(id)) {
      usedIds.add(id);
      return id;
    }
  }
  return (cardIdCounter + 19).toString(); // Should never happen
}

export function makeCardLabel(id: string) {
  return "Kort " + id;
}

export function makeDataSelection(
  subjectPath: string[],
  measureSelection?: MeasureSelection
): DataSelection {
  return {
    id: makeSelectionId(),
    subjectPath,
    measureSelection,
  };
}
export function makeDataSelectionGrouping(
  subjectPath: string[],
  measureSelection?: MeasureSelectionGrouping
): DataSelectionGrouping {
  return {
    id: makeSelectionId(),
    subjectPath,
    measureSelection,
  };
}

const usedIds = new Set<string>();
const usedLabels = new Set<string>();
export function makeIdWithUnusedLabel(): { id: string; label: string } {
  const maxIters = 100;
  let currentIteration = 1;
  while (currentIteration < maxIters) {
    currentIteration += 1;
    const id = makeCardId();
    const label = makeCardLabel(id);
    if (!usedLabels.has(label)) {
      registerId(id);
      registerLabel(label);
      return { id, label };
    }
  }
  throw new Error("Failed to create card label");
}

/** Clone a card, including id */
export function cloneCard(card: DocCardState): DocCardState {
  return cloneDeep(card);
}

/**
 * Register id as used and return the input as-is
 */
export function registerId(id: string): string {
  usedIds.add(id);
  return id;
}

/**
 * Register label as used and return the input as-is
 */
export function registerLabel(label: string): string {
  usedLabels.add(label);
  return label;
}

export type TimeSelectionMode = "range" | "point";
export function getCardTimeSelectionMode(
  card: DocCardStats | DocCardMicro
): TimeSelectionMode {
  if (card.type === "dataCard") {
    return "range";
  } else if (card.type === "microCard") {
    return microSelectionPrimary(card)?.multiSelectEnabled ? "range" : "point";
  }
  assertNever(card);
}

export enum DatasetErrorCode {
  GroupingTimeMismatch = "GroupingTimeMismatch",
  NoSelection = "NoSelection",
  Custom = "Custom",
}
export class DatasetError {
  constructor(private _code: DatasetErrorCode, private _message?: string) {}

  code(): DatasetErrorCode {
    return this._code;
  }

  displayMessage(): string {
    switch (this._code) {
      case DatasetErrorCode.NoSelection:
        return "Välj minst ett mått";
      case DatasetErrorCode.Custom:
        return this._message ?? "Okänt fel";
      case DatasetErrorCode.GroupingTimeMismatch:
        return "Det valda måttet kan inte delas in med hjälp av indelningsmåttet.";
      default:
        assertNever(this._code);
    }
  }
}

/** Can only export simple selections as data link: only a single measure, no filters, no gemetric types. */
export function canExportMicroCardAsDataLink(card: DocCardMicro): boolean {
  if (!cardLoaded(card)) {
    return false;
  }

  const selections = card.data.dataSelections;
  if (!defined(selections) || selections.length !== 1) {
    return false;
  }

  const selection = selections[0];
  const measure = selection.measure;
  if (!defined(measure)) {
    return false;
  }
  if (measure.valueType !== "decimal" && measure.valueType !== "integer") {
    return false;
  }

  if (card.data.filterMeasures.length > 0) {
    return false;
  }

  // We don't currently support any filter functionality
  if (selection.type === "primary" && defined(selection.filterSet)) {
    return false;
  }

  return true;
}

export function shouldHaveSpaceBefore(
  cardSpace?: CardSpaceSettings,
  prevCardSpace?: CardSpaceSettings
): boolean {
  return (cardSpace?.hideSpaceBefore || prevCardSpace?.hideSpaceAfter) ?? false;
}

export function shouldHaveSpaceAfter(
  cardSpace?: CardSpaceSettings,
  nextCardSpace?: CardSpaceSettings
): boolean {
  return (cardSpace?.hideSpaceAfter || nextCardSpace?.hideSpaceBefore) ?? false;
}

export function isDataframeStatsColumn(
  c: DataframeColumn
): c is DataframeStatsColumn {
  return c.type === "stats";
}

export function isDataframeMicroColumn(
  c: DataframeColumn
): c is DataframeMicroColumn {
  return c.type === "micro";
}

export function isUserDefinedColumn(
  c: DataframeColumn
): c is DataframeUserDefinedColumn {
  return c.type === "user-defined";
}

export function cardLoaded(c: DocCardState): boolean {
  switch (c.type) {
    case "dataCard":
    case "microCard":
    case "pythonCard":
      return c.initState === Progress.Success;
    case "textCardCK":
    case "textCardSimple":
    case "microCardImage":
      return true;
    case "error":
      return true;
  }
  assertNever(c);
}
