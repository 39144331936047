import { format } from "date-fns";
import { sv } from "date-fns/locale";

import { SortOrder } from "./order";

export const Milliseconds = {
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
};

function dateStringComparator(order: SortOrder = SortOrder.ascending) {
  const operate = (left: number, right: number) => {
    return order === SortOrder.ascending ? left < right : left > right;
  };
  return (leftRaw: string, rightRaw: string) => {
    if (leftRaw === rightRaw) {
      return 0;
    }
    return operate(new Date(leftRaw).getTime(), new Date(rightRaw).getTime())
      ? -1
      : 1;
  };
}

export const dateStringComparatorAsc = dateStringComparator(
  SortOrder.ascending
);
export const dateStringComparatorDesc = dateStringComparator(
  SortOrder.descending
);

export function utcTimeStringToDate(s: string): Date {
  const year = parseInt(s.slice(0, 4));
  const month = parseInt(s.slice(5, 7)) - 1;
  const date = parseInt(s.slice(8, 10));
  return new Date(Date.UTC(year, month, date));
}

export function dateComparatorAscending(left: Date, right: Date): number {
  return left.getTime() - right.getTime();
}

/**
 * Returns a date string YYYY-MM-DD for the current date - numDaysPast
 */
export function dateStringPastDay(numDaysPast = 1): string {
  const currentDate = new Date();
  return new Date(currentDate.getTime() - Milliseconds.day * numDaysPast)
    .toISOString()
    .slice(0, 10);
}

export function trimRawDate(dateTime: string): string {
  return dateTime.slice(0, 10);
}

export function trimRawTimeHoursMinutes(dateTime: string): string {
  return dateTime.slice(0, 5);
}

export function formatDateTimeSE(dateTime: Date): string {
  return format(dateTime, "yyyy-MM-dd HH:mm", { locale: sv });
}

export const MonthNames = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];
