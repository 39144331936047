import {
  Boolean as BooleanRT,
  Array as ArrayRT,
  Number as NumberRT,
  String as StringRT,
  Record,
  Static,
  Null,
  Optional,
} from "runtypes";

export const OrganizationDtoRT = Record({
  id: StringRT,
  name: StringRT,
});
export const OrganizationsResponseDtoRT = ArrayRT(OrganizationDtoRT);
export type OrganizationDto = Static<typeof OrganizationDtoRT>;
export type OrganizationResponse = Static<typeof OrganizationsResponseDtoRT>;

export const LicenseTypeRT = Record({
  license_id: NumberRT,
  label: StringRT,
  description: StringRT,
});
export type LicenseType = Static<typeof LicenseTypeRT>;
export const LicenseTypesResponseRT = ArrayRT(LicenseTypeRT);
export type LicenseTypesResponse = Static<typeof LicenseTypesResponseRT>;

export const CreateOrganizationResponseRT = StringRT;
export type CreateOrganizationResponse = Static<
  typeof CreateOrganizationResponseRT
>;

const OrganizationUserDtoRT = Record({
  id: StringRT,
  email: StringRT,
  licenses: Null.Or(
    ArrayRT(
      Record({
        license_id: NumberRT,
        label: StringRT,
      })
    )
  ),
  roles: Null.Or(
    ArrayRT(
      Record({
        role_id: NumberRT,
        label: StringRT,
      })
    )
  ),
});
export type OrganizationUserDto = Static<typeof OrganizationUserDtoRT>;
export const OrganizationUsersDtoRT = ArrayRT(OrganizationUserDtoRT);
export type OrganizationUsersDto = Static<typeof OrganizationUsersDtoRT>;

export const OrganizationsListV2DtoRT = ArrayRT(
  Record({
    id: StringRT,
    name: StringRT,
    licenses: Optional(ArrayRT(StringRT).Or(Null)),
  })
);
export type OrganizationsListV2Dto = Static<typeof OrganizationsListV2DtoRT>;

const OrganizationLicenseV2DtoRT = Record({
  license_id: NumberRT,
  label: StringRT,
  max_count: NumberRT,
  current_usage_count: NumberRT,
  current_invite_count: NumberRT,
});
export type OrganizationLicenseV2Dto = Static<
  typeof OrganizationLicenseV2DtoRT
>;

export const OrganizationInviteDtoRT = Record({
  accepted_at: Null.Or(StringRT),
  accepted_by: Null.Or(StringRT),
  created_at: StringRT,
  created_by: StringRT,
  created_by_email: StringRT,
  invited_email: StringRT,
  license_labels: Null.Or(ArrayRT(StringRT)),
  role_labels: Null.Or(ArrayRT(StringRT)),
  organization_id: StringRT,
  organization_invite_id: NumberRT,
  user_notified_at: Null.Or(StringRT),
});
export type OrganizationInviteDto = Static<typeof OrganizationInviteDtoRT>;

const OrganizationRoleDtoRT = Record({
  role_id: NumberRT,
  label: StringRT,
  description: StringRT,
});
const ThirdPartyOrgRT = Record({
  third_party_org_id: StringRT,
  name: StringRT,
  addresses: ArrayRT(
    Record({
      email: StringRT,
      admin: BooleanRT,
    })
  ).Or(Null),
  email_domains: ArrayRT(StringRT),
  max_address_count: NumberRT,
});
export type ThirdPartyOrg = Static<typeof ThirdPartyOrgRT>;

export const ThirdPartyOrgsListDtoRT = ArrayRT(ThirdPartyOrgRT).Or(Null);
export type ThirdPartyOrgsListDto = Static<typeof ThirdPartyOrgsListDtoRT>;

export type OrganizationRoleDto = Static<typeof OrganizationRoleDtoRT>;
export const CustomBrandingDtoRT = Record({
  packaged_doc_logo_link: Optional(StringRT).Or(Null),
  logo_url_big: StringRT,
  logo_url_small: StringRT,
  nav_bg_color: StringRT,
  nav_fg_color: StringRT,
});
export type CustomBrandingDto = Static<typeof CustomBrandingDtoRT>;
export const OrganizationDetailsV2DtoRT = Record({
  id: StringRT,
  name: StringRT,
  licenses: ArrayRT(OrganizationLicenseV2DtoRT).Or(Null),
  users: Null.Or(ArrayRT(OrganizationUserDtoRT)),
  invites: Null.Or(ArrayRT(OrganizationInviteDtoRT)),
  available_roles: ArrayRT(OrganizationRoleDtoRT),
  email_domains: ArrayRT(StringRT),
  third_party_orgs: Optional(ArrayRT(ThirdPartyOrgRT).Or(Null)),
  custom_branding: Optional(CustomBrandingDtoRT),
});
export type OrganizationDetailsV2Dto = Static<
  typeof OrganizationDetailsV2DtoRT
>;

export const OrganizationLicenseDtoRT = Record({
  id: StringRT,
  organization_id: StringRT,
  assigned_at: StringRT,
  email: StringRT,
  active: BooleanRT,
});
export const OrganizationLicensesListDtoRT = ArrayRT(
  OrganizationLicenseDtoRT
).Or(Null);
export type OrganizationLicenseDto = Static<typeof OrganizationLicenseDtoRT>;

export const InstantiateMemberOrgDocsResultDtoRT = ArrayRT(
  Record({
    member_org_name: StringRT,
    document_id: StringRT,
  })
);

export type InstantiateMemberOrgDocsResultDto = Static<
  typeof InstantiateMemberOrgDocsResultDtoRT
>;
