import { useState, useContext, useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { fromPairs, sortBy } from "lodash";

import {
  UserInfoContext,
  AppMessagesContext,
  MicroMapDownloadContext,
  GeographiesContext,
} from "../../../../../lib/application/contexts";
import { EmbeddedModeToolbarSettings } from "../../../../../lib/application/embedded_cards";
import {
  canExportMicroCardAsDataLink,
  DocCardMicro,
  DocCardStats,
} from "../../../../../lib/application/state/stats/document-core/core";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";
import { SurveyDataset } from "../../../../../lib/application/stats/datasets/SurveyDataset";
import { getNextDimensionOrder } from "../../../../../lib/application/stats/shared/dimensions";
import { defined } from "../../../../../lib/core/defined";
import { AddVariableDialog } from "./AddVariableDialog";
import { ColorOptionsDialog } from "./ColorOptionsDialog";
import { FineTuneBreakdownsDialog } from "./FineTuneBreakdowns";
import { ForecastDialog } from "./ForecastDialog";
import { ReferenceSettingsDialog } from "./ReferenceSettingsDialog";
import {
  ToolbarMenu,
  ToolbarMenuItem,
  toggleReferenceValuesMenuItem,
  categoryOrderMenuItem,
  toggleShowSurveyValueFraction,
  decimalsModeItem,
  computedValueMenuItems,
  fineTuneBreakdowns,
  svgExportOptions,
  TabToolbar,
  TogglableMenuItem,
  axisSettingsSubmenu,
  ToolbarButton,
  ToolbarTopLevelItem,
  tablePaddingSidesSubMenu,
} from "./TabToolbar";
import { useForecastDialogMenu } from "./menus/useForecastDialogMenu";
import {
  ChartType,
  Dimension,
} from "../../../../../lib/application/stats/shared/core/definitions";
import { Permission } from "../../../../../lib/application/auth/UserInfo";
import { SurveyStringDataset } from "../../../../../lib/application/stats/datasets/SurveyStringDataset";
import { assertNever } from "../../../../../lib/core/assert";
import { HttpResult } from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { tableToExcel } from "../../../../../lib/application/exports/excel";
import { formatPostalCode } from "../../../../../lib/application/stats/format";
import { PostalExportModal } from "../micro/components/PostalExportModal";
import {
  exportAddresses,
  exportZipcodes,
} from "../../../../../lib/application/requests/address_export";
import { toggleStatePropertyImmut } from "../../../../../lib/application/state/generic";
import {
  MicroMapSettings,
  cardPrimaryMeasureIsComputed,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import {
  replaceMicroMapSetting,
  replaceSelectionWithFilteredSelection,
} from "../../../../../lib/application/state/actions/micro/updates";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { cardQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import { MicroSettingsModal } from "../micro/components/MicroSettingsModal";
import { useFilterMenu } from "../micro/useFilterMenu";
import { simpleTableExcel } from "../../../../../lib/application/exports/simple_table_excel";
import { config } from "../../../../../config";
import { CustomDescriptionDialog } from "./CustomDescriptionDialog";
import { ManualYAxisDialog } from "./ManualYAxisDialog";
import { useApplyChangeMicro } from "../../../../../lib/application/state/stats/useApplyChangeMicro";
import { useApplyChangeStats } from "../../../../../lib/application/state/stats/useApplyChangeStats";
import { AdjustFontSizesDialog } from "./AdjustFontSizesDialog";
import { ComputationSettingsDialog } from "./ComputationSettingsDialog";
import { CardShareDialog } from "./CardShareDialog";
import { AdjustChartElementsDialog } from "./AdjustChartElementsDialog";
import { ExportViaLinkDialog } from "./ExportViaLinkDialog";
import {
  createMikroDataLink,
  createStatsDataLink,
  createSurveyDataLink,
} from "../../../../../lib/application/requests/data_links";
import {
  DataLinkParams,
  getFullDataLinkMikro,
  getFullDataLinkStats,
  getFullDataLinkSurvey,
} from "../../../../../lib/paths";
import { StatsDataset } from "../../../../../lib/application/stats/datasets/StatsDataset";
import { StatsMapColorsAndScalesDialog } from "./StatsMapColorsAndScalesDialog";
import {
  DataOutputSettings,
  DataTableSettings,
} from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { getDataLinkFeatureSupport } from "../../../../../lib/domain/data_links";
import { getGeoSelections } from "../../../../../lib/application/state/stats/document-core/queries/dataCard";

const dataLinkFeatureSupportMicro = getDataLinkFeatureSupport("mikro");
const dataLinkFeatureSupportStats = getDataLinkFeatureSupport("stats");
const dataLinkFeatureSupportSurvey = getDataLinkFeatureSupport("survey");

enum Menu {
  appearance = "appearance",
  calculations = "calculations",
  microFilter = "microFilter",
  share = "share",
  download = "download",
}
enum Feature {
  // Appearance
  chartColors = "chartColors",
  customDescription = "customDescription",
  adjustHeaderFontSizes = "adjustHeaderFontSizes",
  adjustChartElements = "adjustChartElements",
  toggleHeader = "toggleHeader",
  tablePaddingSides = "tablePaddingSides",
  surveyStringToggleAllRows = "surveyStringToggleAllRows",
  surveyStringToggleWeightColumn = "surveyStringToggleWeightColumn",
  statsMapSize = "statsMapSize",
  statsMapToggleRegionOutlines = "statsMapToggleRegionOutlines", // kommun- och länsgränser
  statsMapShowFirstAndLastOnly = "statsMapShowFirstAndLastOnly",
  statsMapColorsAndScales = "statsMapColorsAndScales",
  microReferenceSettings = "microReferenceSettings",
  surveyToggleReferenceValues = "surveyToggleReferenceValues",
  surveyToggleFractions = "surveyToggleFractions",
  dimensionOrdering = "dimensionOrdering",
  decimalPrecisionModes = "decimalPrecisionModes",
  axisSettings = "axisSettings",
  hideValues = "hideValues",
  microShowDesoRegsoLabels = "microShowDesoRegsoLabels",
  microShowDesoRegsoValues = "microShowDesoRegsoValues",
  microMapHideInfoBox = "microMapHideInfoBox",
  microMapColors = "microMapColors",
  microMapToggleRegionBoundaries = "microMapRegionBoundaries",
  microMapToggleMapLabels = "microMapToggleMapLabels",
  microShowGeoMeasuresOnlyInSelectedAreas = "microShowGeoMeasuresOnlyInSelectedAreas",

  // Calculation
  trend = "trend",
  computedValues = "computedValues",
  computationSettings = "computationSettings",
  tableSummaryRows = "tableSummaryRows",

  // Micro filter
  microFilter = "microFilter",

  // Download
  getDataLink = "getDataLink",
  statsMapImage = "statsMapImage",
  chartImage = "chartImage",
  simpleTableExport = "simpleTableExport",
  microMapImage = "microMapImage",
  microMapZipcodes = "microMapZipcodes",
  microMapAddresses = "microMapAddresses",
  tableExport = "tableExport",
  microTableZipcodes = "microTableZipcodes",
}

type Views = "micro-select" | "micro-map" | "chart" | "table" | "map" | "info";
type TableSubView = "numeric" | "string";

type MenuSectionItem = [
  Feature,
  // for "diagram", ChartType[] is the list of supported chart types
  // and for table, TableSubView[] is the list of supported subviews
  // No views specified means all views
  (Views | ["chart", ChartType[]] | ["table", TableSubView[]])[],
  Permission[] | null
];

interface MenuSpec {
  menu: Menu;
  type: "menu";
  hideInThirdPartyDocs: boolean;
  icon: ToolbarMenu["icon"];
  label: string;
  className?: string;
  sections: {
    sortByName?: boolean;
    items: MenuSectionItem[];
  }[];
}

interface TopLevelButtonSpec {
  type: "button";
  className?: string;
  menu: Menu;
  icon: ToolbarMenu["icon"];
  label: string;
}

type MenuSpecOrButton = MenuSpec | TopLevelButtonSpec;

const specLeft: MenuSpecOrButton[] = [
  {
    menu: Menu.appearance,
    type: "menu",
    hideInThirdPartyDocs: false,
    icon: "style",
    className: "appearance-menu",
    label: "Utseende",
    sections: [
      {
        items: [
          [Feature.chartColors, ["chart"], null],
          [Feature.statsMapColorsAndScales, ["map"], null],
          [Feature.statsMapSize, ["map"], null],
          [Feature.customDescription, ["chart", "table", "map"], null],
          [Feature.tablePaddingSides, ["table"], null],
          [Feature.adjustHeaderFontSizes, ["chart", "table", "map"], null],
          [Feature.adjustChartElements, ["chart"], null],
          [Feature.toggleHeader, ["table", "map"], null],
          [Feature.statsMapToggleRegionOutlines, ["map"], null],
          [Feature.statsMapShowFirstAndLastOnly, ["map"], null],
        ],
      },
      {
        sortByName: true,
        items: [
          [Feature.axisSettings, ["chart"], null],
          [Feature.surveyStringToggleAllRows, [["table", ["string"]]], null],
          [
            Feature.surveyStringToggleWeightColumn,
            [["table", ["string"]]],
            null,
          ],
          [Feature.microMapColors, ["micro-select", "micro-map"], null],
          [Feature.microMapHideInfoBox, ["micro-select", "micro-map"], null],
          [
            Feature.microShowGeoMeasuresOnlyInSelectedAreas,
            ["micro-select", "micro-map"],
            null,
          ],
          [Feature.microShowDesoRegsoLabels, ["micro-map"], null],
          [Feature.microShowDesoRegsoValues, ["micro-map"], null],
          [
            Feature.microMapToggleMapLabels,
            ["micro-select", "micro-map"],
            null,
          ],
          [
            Feature.microMapToggleRegionBoundaries,
            ["micro-select", "micro-map"],
            null,
          ],
          [
            Feature.microReferenceSettings,
            ["chart", ["table", ["numeric"]]],
            [Permission.WriteMikro],
          ],
          [
            Feature.surveyToggleReferenceValues,
            ["chart", ["table", ["numeric"]]],
            null,
          ],
          [
            Feature.surveyToggleFractions,
            ["chart", ["table", ["numeric"]]],
            null,
          ],
          [
            Feature.dimensionOrdering,
            [
              ["chart", [ChartType.barHorizontal, ChartType.barVertical]],
              ["table", ["numeric"]],
            ],
            null,
          ],
          [
            Feature.decimalPrecisionModes,
            ["chart", ["table", ["numeric"]], "map"],
            null,
          ],
          [Feature.hideValues, ["chart", ["table", ["numeric"]]], null],
        ],
      },
    ],
  },
  {
    menu: Menu.calculations,
    hideInThirdPartyDocs: true,
    type: "menu",
    icon: "calculator",
    label: "Beräkningar",
    sections: [
      {
        items: [
          [Feature.tableSummaryRows, [["table", ["numeric"]]], null],
          [Feature.trend, [["chart", [ChartType.line]]], null],
        ],
      },
      {
        items: [
          [
            Feature.computedValues,
            ["chart", ["table", ["numeric"]], "map"],
            null,
          ],
          [
            Feature.computationSettings,
            ["chart", ["table", ["numeric"]], "map"],
            null,
          ],
        ],
      },
    ],
  },
  {
    menu: Menu.microFilter,
    hideInThirdPartyDocs: true,
    type: "menu",
    icon: "filter",
    label: "Filter",
    sections: [
      {
        items: [
          [
            Feature.microFilter,
            ["micro-select", "micro-map", "chart", ["table", ["numeric"]]],
            null,
          ],
        ],
      },
    ],
  },
];

const specRight: MenuSpecOrButton[] = [
  {
    menu: Menu.share,
    type: "button",
    icon: "social-media",
    className: "social-media-button",
    label: "Sociala medier",
  },
  {
    menu: Menu.download,
    hideInThirdPartyDocs: false,
    type: "menu",
    icon: "cloud-download",
    label: "Exportera",
    className: "export-menu",
    sections: [
      {
        items: [
          [Feature.getDataLink, ["chart", "micro-map", "table"], null],
          [Feature.chartImage, ["chart"], null],
          [Feature.statsMapImage, ["map"], null],
          [Feature.microMapImage, ["micro-select", "micro-map"], null],
          [Feature.microMapZipcodes, ["micro-select", "micro-map"], null],
          [Feature.microMapAddresses, ["micro-select", "micro-map"], null],
          [Feature.tableExport, [["table", ["numeric", "string"]]], null],
          [Feature.simpleTableExport, [["table", ["string"]]], null],
          [
            Feature.microTableZipcodes,
            [["table", ["numeric"]]],
            [Permission.MikroPostalcodeExport],
          ],
        ],
      },
    ],
  },
];

interface ToolbarElementsProps extends EmbeddedModeToolbarSettings {
  cardRaw: DocCardStats | DocCardMicro;
  isEditing: boolean;
  settings: DataOutputSettings;
  setSettings: (settings: DataOutputSettings) => void;
  isThirdPartyDoc: boolean;
  fetchPostalCodeDataset?: () => Promise<HttpResult<MicroDataset | undefined>>;
}

export function DataOutputToolbar(props: ToolbarElementsProps) {
  const { cardRaw, isEditing, settings, setSettings } = props;
  const dataset = useMemo(() => {
    switch (cardRaw.type) {
      case "dataCard":
        return cardRaw.data.loadedData?.dataset;
      case "microCard":
        return cardRaw.data.loadedData?.primaryDataset;
    }
  }, [cardRaw]);

  const geographies = useContext(GeographiesContext);
  const card = useMemo(() => {
    switch (cardRaw.type) {
      case "microCard":
        return cardRaw;
      case "dataCard":
        if (geographies === undefined) {
          throw new Error("Geographies not loaded");
        }
        return {
          ...cardRaw,
          data: {
            ...cardRaw.data,
            geoSelections: getGeoSelections(cardRaw, geographies),
          },
        } as DocCardStats;
    }
  }, [cardRaw, geographies]);

  const customChartBgColor = useMemo(() => {
    return cardRaw.data.loadedData?.chartDataState?.loadedChartData
      ?.colorSchemeContainer.customBgColor;
  }, [
    cardRaw.data.loadedData?.chartDataState?.loadedChartData
      ?.colorSchemeContainer.customBgColor,
  ]);

  const { emitEvent: emitMicroMapDownloadEvent } = useContext(
    MicroMapDownloadContext
  );

  const isSurvey = dataset instanceof SurveyDataset;
  const isMicro = dataset instanceof MicroDataset;
  const [showChartColorOptions, setShowChartColorOptions] = useState(false);
  const [showCustomDescription, setShowCustomDescription] = useState(false);
  const [showMicroMapColorOptions, setShowMicroMapColorSettings] =
    useState(false);
  const [showComputationSettings, setShowComputationSettings] = useState(false);
  const [showNewVariableDialog, setShowNewVariableDialog] = useState(false);
  const [showAdjustFontSizes, setShowAdjustFontSizes] = useState(false);
  const [showAdjustChartElements, setShowAdjustChartElements] = useState(false);
  const [showFineTuneBreakdowns, setShowFineTuneBreakdowns] = useState(false);
  const [showReferenceSettings, setShowReferenceSettings] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showStatsMapColorsAndScales, setShowStatsMapColorsAndScales] =
    useState(false);
  const [manualYAxisDialogOpen, setManualYAxisDialogOpen] = useState(false);

  const [addressExportOpen, setAddressExportOpen] = useState(false);
  const [exportViaLinkOpen, setExportViaLinkOpen] = useState(false);
  const [zipcodeExportOpen, setZipcodeExportOpen] = useState(false);
  const userInfo = useContext(UserInfoContext);

  const saveCard = useSaveCard();
  const setCard = useSetRecoilState(cardQuery(card.id));

  const appMessagesHandler = useContext(AppMessagesContext);

  const dataDescription = useMemo(() => {
    if (!defined(dataset) || dataset instanceof SurveyStringDataset) {
      return undefined;
    }
    return dataset.dataDescription();
  }, [dataset]);

  const microDataset = useMemo(() => {
    return dataset instanceof MicroDataset ? dataset : undefined;
  }, [dataset]);
  const microSettings =
    card.type === "microCard" ? card.data.settings : undefined;

  const {
    showForecastDialog,
    setShowForecastDialog,
    menuItem: trendMenuItem,
  } = useForecastDialogMenu(card.id, settings);

  const applyChangeMicro = useApplyChangeMicro(card.id);
  const applyChangeStats = useApplyChangeStats(card.id);

  const handleRemoveAllFilters = useCallback(() => {
    if (card.type !== "microCard") {
      return;
    }

    const dataSelections = card.data.dataSelections;
    const updatedCard = {
      ...card,
      data: {
        ...card.data,
        filterMeasures: [],
        dataSelection: defined(dataSelections)
          ? dataSelections.map((ds) => ({ ...ds, filterSet: undefined }))
          : undefined,
      },
    };
    applyChangeMicro(updatedCard);
  }, [applyChangeMicro, card]);

  const handleApplyUpdatedDataOutputSettings = useCallback(
    (settings: DataOutputSettings) => {
      if (card.type === "dataCard") {
        applyChangeStats({ ...card, data: { ...card.data, settings } });
      } else if (card.type === "microCard") {
        applyChangeMicro({
          ...card,
          data: {
            ...card.data,
            settings: {
              ...card.data.settings,
              dataOutputSettings: settings,
            },
          },
        });
      }
    },
    [applyChangeMicro, applyChangeStats, card]
  );

  const handleReplaceSelectionsWithResults = useCallback(() => {
    if (!defined(microDataset)) {
      return;
    }
    if (card.type !== "microCard") {
      return;
    }
    if (!defined(card.data.geoSelections)) {
      return;
    }
    const result = microDataset.filterGeoSelectionsByResults(
      card.data.geoSelections
    );

    const updatedCard = replaceSelectionWithFilteredSelection(card, result);
    applyChangeMicro(updatedCard);
  }, [applyChangeMicro, card, microDataset]);

  const microFilterMenu = useFilterMenu(
    card.id,
    handleReplaceSelectionsWithResults,
    handleRemoveAllFilters
  );

  const setNextFixedDimensionsOrder = useCallback(() => {
    if (!defined(dataset)) {
      return;
    }

    const nextOrder = getNextDimensionOrder(
      dataset.nonPrimaryDataDimensions() ?? [],
      settings.fixedDimensionOrder,
      false
    );
    if (!defined(nextOrder)) {
      return;
    }
    setSettings({
      ...settings,
      fixedDimensionOrder: nextOrder,
    });
  }, [dataset, setSettings, settings]);

  const resetFixedDimensionsOrder = useCallback(() => {
    setSettings({ ...settings, fixedDimensionOrder: null });
  }, [setSettings, settings]);

  const dimensionToLabel = useCallback(
    (dimension: string) => {
      if (!defined(dataDescription)) {
        return;
      }
      return dataDescription.dimensionToLabel(dimension) ?? "[saknas]";
    },
    [dataDescription]
  );

  const handleUpdateMicroMapSetting = useCallback(
    <T extends keyof MicroMapSettings>(
      setting: T,
      settingValue: MicroMapSettings[T]
    ) => {
      const mapSettings = microSettings?.map;
      if (!defined(mapSettings)) {
        logger.warn("No map settings found for micro card", card.id);
        return;
      }
      if (card.type !== "microCard") {
        logger.warn("Card is not a micro card", card.id);
        return;
      }

      const updatedSettings = replaceMicroMapSetting(
        mapSettings,
        setting,
        settingValue
      );
      const updatedCard: DocCardMicro = {
        ...card,
        data: {
          ...card.data,
          settings: {
            ...card.data.settings,
            map: updatedSettings,
          },
        },
      };
      saveCard?.(updatedCard);
      setCard(updatedCard);
    },
    [card, microSettings?.map, saveCard, setCard]
  );

  const isCurrentView = useCallback(
    (view: Views): boolean => {
      switch (card.type) {
        case "dataCard":
          switch (view) {
            case "micro-select":
              return false;
            case "micro-map":
              return false;
            case "map":
              return card.data.selectedView === "map";
            case "chart":
              return card.data.selectedView === "diagram";
            case "table":
              return card.data.selectedView === "table";
            case "info":
              return card.data.selectedView === "info";
          }
          break;
        case "microCard":
          switch (view) {
            case "micro-select":
              return card.data.selectedTab === "map-select";
            case "micro-map":
              return card.data.selectedTab === "map-view";
            case "chart":
              return card.data.selectedTab === "chart";
            case "map":
              return false;
            case "table":
              return card.data.selectedTab === "table";
            case "info":
              return card.data.selectedTab === "info";
          }
          break;
      }
    },
    [card]
  );
  const buildMenu = useCallback(
    (spec: MenuSpec): ToolbarMenuItem[] | undefined => {
      if (!props.showToolbar) {
        return;
      }
      const allItems: ToolbarMenuItem[] = [];

      if (spec.hideInThirdPartyDocs && props.isThirdPartyDoc) {
        return;
      }

      for (const section of spec.sections) {
        const items: ToolbarMenuItem[] = [];
        for (const item of section.items) {
          const [feature, views, permissions] = item;
          if (defined(permissions)) {
            if (!defined(userInfo)) {
              continue;
            }
            const allOk =
              permissions.length === 0
                ? false
                : permissions.every((p) => userInfo.hasPermission(p));
            if (!allOk) {
              continue;
            }
          }

          // Check that view is supported
          const supported = views.some((view) => {
            // Non-chart views. Requires that current view matches only
            if (typeof view === "string") {
              return isCurrentView(view);
            }

            // Current view must match
            if (!isCurrentView(view[0])) {
              return false;
            }

            // Current view is already checked. If no subviews, then we have a match.
            if (!defined(view[1])) {
              return;
            }

            switch (view[0]) {
              case "chart": {
                // Chart type must match if specified
                const chartTypes = view[1];
                return chartTypes.some((t) => {
                  return (
                    t ===
                    card.data.loadedData?.chartDataState?.loadedChartData
                      ?.chartType
                  );
                });
              }
              case "table": {
                // Subview must match if specified
                const subviews = view[1];
                return subviews.some((subview) => {
                  // Micro cards have only numeric, no string data
                  if (card.type === "microCard") {
                    return subview === "numeric";
                  }
                  const valueType =
                    card.data.loadedData?.tableDataState?.loadedTableData
                      ?.valueType;

                  if (subview === "string") {
                    return (
                      card.data.loadedData?.dataset instanceof
                        SurveyStringDataset ||
                      ["category", "survey_string"].includes(valueType ?? "")
                    );
                  }
                  return ["decimal", "integer", "survey"].includes(
                    valueType ?? ""
                  );
                });
              }
              default:
                assertNever(view[0]);
            }
          });

          if (!supported) {
            continue;
          }

          const table = card.data.loadedData?.tableDataState?.loadedTableData;
          switch (feature) {
            case Feature.chartColors:
              items.push({
                type: "click",
                label: "Färger och teman ...",
                onClick: () => setShowChartColorOptions(true),
              });
              break;
            case Feature.customDescription:
              items.push({
                type: "click",
                label: "Anpassa texter ...",
                onClick: () => setShowCustomDescription(true),
              });
              break;
            case Feature.adjustHeaderFontSizes:
              items.push({
                type: "click",
                label: "Anpassa textstorlekar ...",
                onClick: () => setShowAdjustFontSizes(true),
              });
              break;
            case Feature.adjustChartElements:
              items.push({
                type: "click",
                label: "Anpassa diagramelement ...",
                onClick: () => setShowAdjustChartElements(true),
              });
              break;
            case Feature.toggleHeader:
              items.push({
                type: "toggle",
                label: "Visa rubriker",
                toggled: !settings.hideChartTitleSection,
                onClick: () => {
                  setSettings({
                    ...settings,
                    hideChartTitleSection: !settings.hideChartTitleSection,
                  });
                },
              });
              break;
            case Feature.statsMapToggleRegionOutlines:
              items.push({
                type: "toggle",
                label: "Visa kommun-/länsgränser",
                toggled: settings.mapChart.showAdministrativeBorders,
                onClick: () => {
                  const nextMapChartSettings = toggleStatePropertyImmut(
                    settings.mapChart,
                    "showAdministrativeBorders"
                  );
                  setSettings({
                    ...settings,
                    mapChart: nextMapChartSettings,
                  });
                },
              });
              break;
            case Feature.statsMapShowFirstAndLastOnly:
              items.push({
                type: "toggle",
                label:
                  "Visa endast de första och sista tidpunkterna i perioden",
                toggled: settings.mapChart.showOnlyFirstAndLastDate,
                onClick: () => {
                  setSettings({
                    ...settings,
                    mapChart: toggleStatePropertyImmut(
                      settings.mapChart,
                      "showOnlyFirstAndLastDate"
                    ),
                  });
                },
              });
              break;
            case Feature.tablePaddingSides:
              items.push(tablePaddingSidesSubMenu(settings, setSettings));
              break;
            case Feature.statsMapSize:
              items.push({
                type: "submenu",
                label: "Kartstorlek",
                items: [
                  {
                    type: "toggle",
                    toggled:
                      settings.mapChart.mapSize === "small" ||
                      !defined(settings.mapChart.mapSize),
                    label: "Liten",
                    onClick: () => {
                      setSettings({
                        ...settings,
                        mapChart: {
                          ...settings.mapChart,
                          mapSize: "small",
                        },
                      });
                    },
                  },
                  {
                    type: "toggle",
                    toggled: settings.mapChart.mapSize === "medium",
                    label: "Mellan",
                    onClick: () => {
                      setSettings({
                        ...settings,
                        mapChart: {
                          ...settings.mapChart,
                          mapSize: "medium",
                        },
                      });
                    },
                  },
                  {
                    type: "toggle",
                    toggled: settings.mapChart.mapSize === "large",
                    label: "Stor",
                    onClick: () => {
                      setSettings({
                        ...settings,
                        mapChart: {
                          ...settings.mapChart,
                          mapSize: "large",
                        },
                      });
                    },
                  },
                ],
              });
              break;
            case Feature.statsMapColorsAndScales:
              items.push({
                type: "click",
                label: "Färger ...",
                onClick: () => setShowStatsMapColorsAndScales(true),
              });
              break;
            case Feature.axisSettings:
              const chartType =
                card.data.loadedData?.chartDataState?.loadedChartData
                  ?.chartType;
              items.push(
                axisSettingsSubmenu(
                  settings,
                  setSettings,
                  () => setManualYAxisDialogOpen(true),
                  chartType
                )
              );
              break;
            case Feature.microReferenceSettings:
              if (isMicro) {
                items.push({
                  type: "click",
                  label: "Referensvärden",
                  onClick: () => setShowReferenceSettings(true),
                });
              }
              break;
            case Feature.surveyToggleReferenceValues:
              if (!defined(dataset)) {
                break;
              }
              if (isSurvey && dataset.canUseReferenceValues()) {
                items.push(
                  toggleReferenceValuesMenuItem(settings, setSettings)
                );
              }
              break;
            case Feature.dimensionOrdering:
              if (!defined(dataset)) {
                break;
              }
              items.push(
                categoryOrderMenuItem(
                  settings,
                  resetFixedDimensionsOrder,
                  dataset.canChangeDimensionOrder()
                    ? setNextFixedDimensionsOrder
                    : undefined
                )
              );
              break;
            case Feature.surveyStringToggleAllRows: {
              if (card.type !== "dataCard") {
                break;
              }
              const table =
                card.data.loadedData?.tableSurveyStringDataState?.loadedData;
              items.push({
                type: "toggle",
                toggled: !(
                  settings.tableSurveyString.sliceHeadAndTailRows ?? true
                ),
                disabled:
                  !defined(table) ||
                  table.rows.length < config.surveyString.numDefaultRowsTable,
                label: "Visa alla rader",
                onClick: () => {
                  setSettings({
                    ...settings,
                    tableSurveyString: {
                      ...settings.tableSurveyString,
                      sliceHeadAndTailRows:
                        !settings.tableSurveyString.sliceHeadAndTailRows,
                    },
                  });
                },
              });
              break;
            }
            case Feature.surveyStringToggleWeightColumn:
              items.push({
                type: "toggle",
                toggled: settings.tableSurveyString.showWeightColumn ?? false,
                label: "Visa vikter",
                onClick: () => {
                  setSettings({
                    ...settings,
                    tableSurveyString: {
                      ...settings.tableSurveyString,
                      showWeightColumn:
                        !settings.tableSurveyString.showWeightColumn,
                    },
                  });
                },
              });
              break;
            case Feature.computedValues:
              if (!defined(dataset)) {
                break;
              }

              items.push(
                ...computedValueMenuItems(
                  () => setShowNewVariableDialog(true),
                  !dataset.canMakeComputedValue ?? false
                )
              );
              break;
            case Feature.computationSettings:
              if (!defined(dataset)) {
                break;
              }
              items.push({
                type: "click",
                label: "Inställningar ...",
                onClick: () => setShowComputationSettings(true),
              });
              break;
            case Feature.decimalPrecisionModes:
              if (!defined(dataset)) {
                break;
              }
              if (dataset.supportsDecimalModes) {
                items.push(decimalsModeItem(settings, setSettings));
              }
              break;
            case Feature.surveyToggleFractions:
              if (!defined(dataset) || !isSurvey) {
                break;
              }
              if (card.type !== "dataCard") {
                break;
              }
              items.push(toggleShowSurveyValueFraction(card, applyChangeStats));
              break;
            case Feature.hideValues:
              // finetune breakdowns
              if (!defined(dataset)) {
                break;
              }
              items.push(
                fineTuneBreakdowns(() => setShowFineTuneBreakdowns(true))
              );
              break;
            case Feature.trend:
              items.push(trendMenuItem);
              break;
            case Feature.tableSummaryRows:
              if (table?.valueType !== "category") {
                items.push({
                  type: "submenu",
                  toggled: settings.table.showSumRow,
                  label: "Sammanställningsrad",
                  items: (
                    [
                      {
                        type: "toggle",
                        toggled: Object.values(settings.table).every((v) => !v),
                        label: "Ingen",
                        onClick: () => {
                          setSettings({
                            ...settings,
                            table: {
                              ...settings.table,
                              showSumRow: false,
                              showMaxRow: false,
                              showMinRow: false,
                              showMeanRow: false,
                              showMedianRow: false,
                            },
                          });
                        },
                      },
                    ] as TogglableMenuItem[]
                  ).concat(
                    (
                      [
                        "showSumRow",
                        "showMaxRow",
                        "showMinRow",
                        "showMeanRow",
                        "showMedianRow",
                      ] as const
                    ).map<TogglableMenuItem>((part) => {
                      return {
                        type: "toggle",
                        label: settingToLabel[part] ?? part,
                        toggled: settings.table[part] === true,
                        onClick: () => {
                          setSettings({
                            ...settings,
                            table: {
                              ...settings.table,
                              [part]: defined(settings.table[part])
                                ? !settings.table[part]
                                : true,
                            },
                          });
                        },
                      };
                    })
                  ),
                } as ToolbarMenuItem);
              }
              break;
            case Feature.chartImage:
              items.push(
                ...svgExportOptions(
                  card.id,
                  card.label,
                  customChartBgColor,
                  appMessagesHandler
                )
              );
              break;
            case Feature.statsMapImage:
              items.push(
                ...svgExportOptions(
                  card.id,
                  card.label,
                  undefined,
                  appMessagesHandler
                )
              );
              break;
            case Feature.microMapImage:
              items.push({
                type: "click",
                icon: "media",
                label: "Spara bild",
                onClick: emitMicroMapDownloadEvent,
              });
              break;
            case Feature.microMapHideInfoBox:
              if (!defined(microSettings)) {
                break;
              }
              items.push({
                type: "toggle",
                label: "Visa inforuta",
                toggled: microSettings.map.showInfoBox ?? true,
                onClick: () => {
                  const next = !(microSettings.map.showInfoBox ?? true);
                  handleUpdateMicroMapSetting("showInfoBox", next);
                },
              });
              break;
            case Feature.microMapColors:
              items.push({
                type: "click",
                label: "Färger ...",
                onClick: () => setShowMicroMapColorSettings(true),
              });
              break;
            case Feature.microMapToggleMapLabels:
              if (!defined(microSettings)) {
                break;
              }
              items.push({
                type: "toggle",
                label: "Visa etiketter på karta",
                toggled: microSettings.map.showMapLabels ?? true,
                onClick: () => {
                  const next = !microSettings.map.showMapLabels;
                  handleUpdateMicroMapSetting("showMapLabels", next);
                },
              });
              break;
            case Feature.microShowDesoRegsoLabels:
              if (!defined(microSettings)) {
                break;
              }
              items.push({
                type: "toggle",
                label: "Visa områdesetiketter",
                toggled: microSettings.map.showDesoRegsoLabels ?? false,
                onClick: () => {
                  const next = !microSettings.map.showDesoRegsoLabels;
                  handleUpdateMicroMapSetting("showDesoRegsoLabels", next);
                },
              });
              break;
            case Feature.microShowDesoRegsoValues:
              if (!defined(microSettings)) {
                break;
              }
              items.push({
                type: "toggle",
                label: "Visa områdesvärden",
                toggled: microSettings.map.showDesoRegsoValues ?? false,
                onClick: () => {
                  const next = !microSettings.map.showDesoRegsoValues;
                  handleUpdateMicroMapSetting("showDesoRegsoValues", next);
                },
              });
              break;
            case Feature.microMapToggleRegionBoundaries:
              if (!defined(microSettings)) {
                break;
              }
              items.push({
                type: "toggle",
                label: "Visa områdesgränser",
                toggled: microSettings.map.showBorders,
                onClick: () => {
                  handleUpdateMicroMapSetting(
                    "showBorders",
                    !microSettings.map.showBorders
                  );
                },
              });
              break;
            case Feature.microShowGeoMeasuresOnlyInSelectedAreas:
              if (!defined(microSettings)) {
                break;
              }
              items.push({
                type: "toggle",
                label: "Visa geomått endast i valda områden",
                toggled:
                  microSettings.map.hideGeoObjectsOutsideSelection ?? false,
                onClick: () => {
                  handleUpdateMicroMapSetting(
                    "hideGeoObjectsOutsideSelection",
                    !microSettings.map.hideGeoObjectsOutsideSelection
                  );
                },
              });
              break;
            case Feature.microTableZipcodes:
              const fetchPostalCodeDataset = props.fetchPostalCodeDataset;
              if (!isMicro || !defined(fetchPostalCodeDataset)) {
                break;
              }
              items.push({
                label: "Excel (.xlsx) med postnummer",
                icon: "media",
                type: "click",
                onClick: () => {
                  return fetchPostalCodeDataset().then((res) => {
                    res.match({
                      ok: (postalCodeDataset) => {
                        if (!defined(postalCodeDataset)) {
                          return;
                        }
                        const tableSpec = postalCodeDataset.table();
                        const primary =
                          tableSpec.primaryDimensionInfo?.dimension;
                        const formatRowLabels =
                          primary === Dimension.region
                            ? formatPostalCode
                            : undefined;
                        tableToExcel(tableSpec, `${card.label}.xlsx`, {
                          formatRowLabels,
                        });
                      },
                      err: (err) => {
                        logger.error(
                          "Failed to fetch postal code dataset",
                          err
                        );
                      },
                    });
                  });
                },
              });
              break;
            case Feature.microFilter:
              if (!defined(microFilterMenu)) {
                break;
              }
              items.push(...microFilterMenu.items);
              break;
            case Feature.microMapZipcodes:
              items.push({
                icon: "th",
                label: "Postnummer",
                type: "click",
                onClick: () => setZipcodeExportOpen(true),
              });
              break;
            case Feature.microMapAddresses:
              items.push({
                icon: "th",
                label: "Adresser",
                type: "click",
                onClick: () => setAddressExportOpen(true),
              });
              break;
            case Feature.getDataLink:
              if (card.type !== "dataCard" && card.type !== "microCard") {
                break;
              }
              if (
                card.type === "microCard" &&
                !canExportMicroCardAsDataLink(card)
              ) {
                break;
              }
              items.push({
                icon: "link",
                type: "click",
                label: "Via länk ...",
                onClick: () => setExportViaLinkOpen(true),
              });
              break;
            case Feature.tableExport:
              if (!defined(table)) {
                break;
              }
              items.push({
                icon: "media",
                label: "Excel (.xlsx)",
                type: "click",
                onClick: () => {
                  tableToExcel(table, `${card.label}.xlsx`);
                },
              });
              break;
            case Feature.simpleTableExport: {
              if (card.type !== "dataCard") {
                break;
              }
              const table =
                card.data.loadedData?.tableSurveyStringDataState?.loadedData;
              if (!defined(table)) {
                break;
              }

              items.push({
                icon: "media",
                label: "Excel (.xlsx)",
                type: "click",
                onClick: () => {
                  const dict: { [key: string]: (string | number)[] } =
                    fromPairs(table.cols.map((col) => [col.label, []]));
                  for (const row of table.rows) {
                    for (const col of table.cols) {
                      dict[col.label].push(row[col.key] ?? "");
                    }
                  }
                  simpleTableExcel(
                    `${card.label}.xlsx`,
                    dict,
                    table.tableDescription,
                    table.sourceInfo
                  );
                },
              });
              break;
            }
            default:
              assertNever(feature);
          }
        }

        if (allItems.length > 0) {
          allItems.push({ type: "divider" as const });
        }

        allItems.push(
          ...(section.sortByName
            ? sortBy(items, (item) =>
                item.type !== "divider" ? item.label : 0
              )
            : items)
        );
      }

      return allItems;
    },
    [
      appMessagesHandler,
      applyChangeStats,
      card,
      customChartBgColor,
      dataset,
      emitMicroMapDownloadEvent,
      handleUpdateMicroMapSetting,
      isCurrentView,
      isMicro,
      isSurvey,
      microFilterMenu,
      microSettings,
      props.fetchPostalCodeDataset,
      props.isThirdPartyDoc,
      props.showToolbar,
      resetFixedDimensionsOrder,
      setNextFixedDimensionsOrder,
      setSettings,
      settings,
      trendMenuItem,
      userInfo,
    ]
  );

  const getTopLevelButton = useCallback(
    (spec: TopLevelButtonSpec): ToolbarButton | undefined => {
      switch (spec.menu) {
        case Menu.share:
          const validView = isCurrentView("chart");
          if (!validView) {
            return;
          }

          return {
            type: "button",
            icon: spec.icon,
            label: spec.label,
            className: spec.className,
            onClick: () => {
              setShowShareDialog(true);
            },
          };
        default:
          logger.warn("Unknown top level button", spec.menu);
      }
      return;
    },
    [isCurrentView]
  );

  const menusFromSpecs = useCallback(
    (specs: MenuSpecOrButton[]): ToolbarTopLevelItem[] => {
      const menus: ToolbarTopLevelItem[] = [];
      for (const spec of specs) {
        // Do not show share button in embedded mode, since it depends
        // on being able to, as an authorized user, uploading images
        // to our backend
        if (spec.menu === Menu.share && config.appMode === "embedded") {
          continue;
        }

        if (spec.type === "button") {
          const menu = getTopLevelButton(spec);
          if (!defined(menu)) {
            continue;
          }
          menus.push(menu);
          continue;
        }

        const items = buildMenu(spec);
        if (
          !defined(items) ||
          items.filter((item) => item.type !== "divider").length === 0
        ) {
          continue;
        }

        menus.push({
          type: "menu",
          className: spec.className,
          icon: spec.icon,
          label: spec.label,
          items: items,
        });
      }

      return menus;
    },
    [buildMenu, getTopLevelButton]
  );

  const toolbarMenus: ToolbarTopLevelItem[] = useMemo(() => {
    if (!props.showToolbar || !props.showToolbarSettings) {
      return [];
    }
    return menusFromSpecs(specLeft);
  }, [props.showToolbar, props.showToolbarSettings, menusFromSpecs]);

  const menusRight: ToolbarTopLevelItem[] = useMemo(() => {
    if (!props.showToolbar || !props.showToolbarDownload) {
      return [];
    }
    return menusFromSpecs(specRight);
  }, [menusFromSpecs, props.showToolbar, props.showToolbarDownload]);

  return (
    <>
      {showChartColorOptions && (
        <ColorOptionsDialog
          cardId={card.id}
          onClose={() => setShowChartColorOptions(false)}
          isOpen={showChartColorOptions}
          settings={settings}
          setSettings={handleApplyUpdatedDataOutputSettings}
        ></ColorOptionsDialog>
      )}

      {card.type === "microCard" && (
        <>
          {showMicroMapColorOptions && (
            <MicroSettingsModal
              cardId={card.id}
              primaryMeasureIsComputed={cardPrimaryMeasureIsComputed(card)}
              settings={card.data.settings.map}
              handleUpdateSetting={handleUpdateMicroMapSetting}
              handleClose={() => setShowMicroMapColorSettings(false)}
            ></MicroSettingsModal>
          )}
          {addressExportOpen &&
            defined(card.data.geoSelections) &&
            card.type === "microCard" &&
            defined(microDataset) && (
              <PostalExportModal
                exportTypeLabel="adresser"
                microDataset={microDataset}
                originalGeoSelections={card.data.geoSelections}
                exportFunc={exportAddresses}
                hasAccess={userInfo?.hasExportMicroAddressesAccess() ?? false}
                handleClose={() => setAddressExportOpen(false)}
              ></PostalExportModal>
            )}

          {zipcodeExportOpen &&
            defined(card.data.geoSelections) &&
            defined(microDataset) && (
              <PostalExportModal
                exportTypeLabel="postnummer"
                originalGeoSelections={card.data.geoSelections}
                microDataset={microDataset}
                exportFunc={exportZipcodes}
                hasAccess={userInfo?.hasExportMicroZipcodesAccess() ?? false}
                handleClose={() => setZipcodeExportOpen(false)}
              ></PostalExportModal>
            )}
        </>
      )}
      {exportViaLinkOpen && (
        <>
          {card.type === "microCard" && defined(microDataset) && (
            <ExportViaLinkDialog
              featureSupport={dataLinkFeatureSupportMicro}
              exportFunc={(params: DataLinkParams, description: string) =>
                createMikroDataLink(card, description).then((res) => {
                  return res.map((linkRes) => ({
                    link: getFullDataLinkMikro(linkRes.link, params),
                  }));
                })
              }
              handleClose={() => setExportViaLinkOpen(false)}
            />
          )}
          {card.type === "dataCard" && defined(dataset) && (
            <>
              {dataset instanceof StatsDataset && (
                <ExportViaLinkDialog
                  featureSupport={dataLinkFeatureSupportStats}
                  exportFunc={(params: DataLinkParams, description: string) =>
                    createStatsDataLink(card, description).then((res) =>
                      res.map((linkRes) => ({
                        link: getFullDataLinkStats(linkRes.link, params),
                      }))
                    )
                  }
                  handleClose={() => setExportViaLinkOpen(false)}
                />
              )}
              {dataset instanceof SurveyDataset && (
                <ExportViaLinkDialog
                  featureSupport={dataLinkFeatureSupportSurvey}
                  exportFunc={(params: DataLinkParams, description: string) =>
                    createSurveyDataLink(card, description).then((res) =>
                      res.map((linkRes) => ({
                        link: getFullDataLinkSurvey(linkRes.link, params),
                      }))
                    )
                  }
                  handleClose={() => setExportViaLinkOpen(false)}
                />
              )}
            </>
          )}
        </>
      )}
      {showStatsMapColorsAndScales &&
        dataset instanceof StatsDataset &&
        card.type === "dataCard" && (
          <StatsMapColorsAndScalesDialog
            card={card}
            isOpen={showStatsMapColorsAndScales}
            dataset={dataset}
            settings={settings}
            onClose={() => setShowStatsMapColorsAndScales(false)}
            setSettings={handleApplyUpdatedDataOutputSettings}
          />
        )}
      {showShareDialog && (
        <CardShareDialog
          card={card}
          isOpen={showShareDialog}
          onClose={() => setShowShareDialog(false)}
        />
      )}
      {showComputationSettings && (
        <ComputationSettingsDialog
          cardId={card.id}
          onClose={() => setShowComputationSettings(false)}
          settings={settings}
          setSettings={handleApplyUpdatedDataOutputSettings}
        ></ComputationSettingsDialog>
      )}
      {showAdjustChartElements && (
        <AdjustChartElementsDialog
          cardId={card.id}
          onClose={() => setShowAdjustChartElements(false)}
          settings={settings}
          setSettings={handleApplyUpdatedDataOutputSettings}
        />
      )}
      {showAdjustFontSizes && (
        <AdjustFontSizesDialog
          cardId={card.id}
          onClose={() => setShowAdjustFontSizes(false)}
          settings={settings}
          setSettings={handleApplyUpdatedDataOutputSettings}
        />
      )}
      {showCustomDescription && (
        <CustomDescriptionDialog
          cardId={card.id}
          onClose={() => setShowCustomDescription(false)}
        ></CustomDescriptionDialog>
      )}
      {showForecastDialog && (
        <ForecastDialog
          isOpen={showForecastDialog}
          onClose={() => setShowForecastDialog(false)}
          cardId={card.id}
        />
      )}
      {manualYAxisDialogOpen && (
        <ManualYAxisDialog
          onClose={() => setManualYAxisDialogOpen(false)}
          cardId={card.id}
        />
      )}
      {defined(dataset) && !(dataset instanceof SurveyStringDataset) && (
        <>
          {showNewVariableDialog && (
            <AddVariableDialog
              dataset={dataset}
              dimensionToLabel={dimensionToLabel}
              cardId={card.id}
              onClose={() => setShowNewVariableDialog(false)}
              isOpen={showNewVariableDialog}
            ></AddVariableDialog>
          )}
          {showFineTuneBreakdowns && (
            <FineTuneBreakdownsDialog
              dataset={dataset}
              dimensionToLabel={dimensionToLabel}
              cardId={card.id}
              onClose={() => setShowFineTuneBreakdowns(false)}
              isOpen={showFineTuneBreakdowns}
            ></FineTuneBreakdownsDialog>
          )}
        </>
      )}
      {showReferenceSettings && (
        <ReferenceSettingsDialog
          isOpen={showReferenceSettings}
          onClose={() => setShowReferenceSettings(false)}
          aggMethodGeoMicro={
            dataset instanceof MicroDataset ? dataset.aggMethodGeo() : undefined
          }
          settings={settings}
          setSettings={setSettings}
        ></ReferenceSettingsDialog>
      )}
      {isEditing && (toolbarMenus.length > 0 || menusRight.length > 0) && (
        <TabToolbar menus={toolbarMenus} menusRight={menusRight}></TabToolbar>
      )}
    </>
  );
}

const settingToLabel: { [key in keyof DataTableSettings]: string } = {
  showSumRow: "Summa",
  showMaxRow: "Maximum",
  showMinRow: "Minimum",
  showMeanRow: "Medel",
  showMedianRow: "Median",
};
