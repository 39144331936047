import { v4 as uuidv4 } from "uuid";

import { defined } from "../../../../core/defined";
import { MeasureDto } from "../../../../domain/measure/definitions";
import {
  DataSelection,
  DataSelectionWithoutId,
} from "../../../../domain/selections/definitions";
import {
  CardColorSchemeLink,
  CardStyleContainerGeoMicroLink,
  ColorSchemeContainer,
  StyleContainerGeoMicro,
} from "../document-style/definitions";
import {
  DocCardMicro,
  DocCardState,
  DocCardStats,
} from "../document-core/core";
import {
  reportMetaPersistentDefault,
  ReportMetaPersistentSharing,
} from "../document-meta/definitions";
import { CURRENT_WORKSPACE_VERSION, DataSelectionMinimal } from "./types";
import {
  DataSelectionMicro,
  MicroGeoSelections,
} from "../document-core/core-micro";
import { cardColors } from "../document-core/queries/shared";
import { assertNever } from "../../../../core/assert";
import {
  initializeColorSchemeContainerV8,
  parsePartialDataOutputSettingsV8,
} from "./v8/workspaceToStateV8";
import {
  DataSelectionMicroDto,
  GeoSelectionsMicroDto,
  WorkspacePythonCardV8,
  WorkspaceV8,
  WorkspaceV8RT,
} from "./v8/types";
import { fromPairs } from "lodash";
import { measureSelectionIsRegular } from "../../../../domain/measure";

export type WorkspaceLatest = WorkspaceV8;
export type WorkspaceCardLatest = WorkspaceLatest["state"]["cards"][0];
export const WorkspaceLatestRT = WorkspaceV8RT;
export const parsePartialDataOutputSettingsLatest =
  parsePartialDataOutputSettingsV8;
export const initializeColorSchemeContainerLatest =
  initializeColorSchemeContainerV8;

export function cardToThirdPartyDocTemplateCard(card: DocCardStats) {
  let placeholderInfo: NonNullable<
    ReturnType<typeof mapDataSelectionToTemplate>
  >["placeholderInfo"];
  type NewType = ReturnType<typeof mapDataSelectionToTemplate>;

  const dataSelections: Omit<NonNullable<NewType>, "placeholderInfo">[] = [];
  for (const dataSelection of card.data.dataSelections) {
    const mappedSelection = mapDataSelectionToTemplate(dataSelection);
    if (defined(mappedSelection)) {
      dataSelections.push({
        breakdownSelection: mappedSelection.breakdownSelection,
        measureId: mappedSelection.measureId,
        measureType: mappedSelection.measureType,
      });
      if (defined(placeholderInfo)) {
        throw new Error("Multiple placeholder info -- should not happen!");
      }
      placeholderInfo = mappedSelection.placeholderInfo;
    }
  }

  if (dataSelections.length === 0) {
    throw new Error("No data selections -- should not happen!");
  }

  return {
    card: {
      type: card.type,
      id: card.id,
      label: card.label,
      isEditing: card.isEditing,
      pageBreak: card.pageBreak,
      hideSpaceBefore: card.hideSpaceBefore,
      hideSpaceAfter: card.hideSpaceAfter,
      data: {
        selectedView: card.data.selectedView,
        thirdPartyDataCardSettings: card.data.thirdPartyDataCardSettings,
        settings: card.data.settings,
        dataSelections,
        groupingSelection: defined(card.data.groupingSelection)
          ? mapDataSelection(card.data.groupingSelection)
          : undefined,
        geoSelections: card.data.geoSelections,
        lockToLatestTime: card.data.lockToLatestTime,
        timeSelection: card.data.timeSelection,
      },
    },
    placeholderInfo,
  };
}

function mapDataSelectionToTemplate(selection: DataSelection) {
  const measureSelection = selection.measureSelection;
  if (!defined(measureSelection)) {
    return;
  }
  if (!measureSelectionIsRegular(measureSelection)) {
    throw new Error("Templates only used for regular measures");
  }

  if (measureSelection.measure.is_member_org_measurement_parent) {
    const measureIdPlaceholder = `$M_ID_${measureSelection.measure.data_id}$`;
    const breakdownPlaceholdersAndInfo = Object.entries(
      measureSelection.breakdowns
    ).map(([key, value], i) => {
      const placeholder = `$BR_${measureSelection.measure.data_id}_${selection.id}_${key}$`;
      return {
        key,
        info:
          i === 0
            ? { type: "org_breakdown", placeholder }
            : { type: "breakdown", parentMeasureBreakdownValueIds: value },
        placeholder,
      };
    });
    return {
      measureType: measureSelection.measure.value_type,
      measureId: measureIdPlaceholder,
      breakdownSelection: fromPairs(
        breakdownPlaceholdersAndInfo.map((b) => [b.key, b.placeholder])
      ),
      placeholderInfo: {
        parent_measure_id: measureSelection.measure.data_id,
        id_placeholder: measureIdPlaceholder,
        breakdown_placeholders: breakdownPlaceholdersAndInfo.map((b) => {
          return {
            data_column: b.key,
            type: b.info.type,
            placeholder: b.placeholder,
            parent_measure_breakdown_value_ids:
              b.info.parentMeasureBreakdownValueIds,
          };
        }),
      },
    };
  }
  return {
    measureType: measureSelection.measure.value_type,
    measureId: measureSelection.measure.data_id,
    breakdownSelection: measureSelection.breakdowns,
  };
}

export function cardToWorkspaceCardLatest(
  card: DocCardState
): WorkspaceCardLatest | undefined {
  switch (card.type) {
    case "dataCard": {
      const dataSelections = card.data.dataSelections
        .map((selection) => {
          return mapDataSelection(selection);
        })
        .filter(defined);
      if (dataSelections.length === 0) {
        return undefined;
      }
      return {
        type: card.type,
        id: card.id,
        label: card.label,
        isEditing: card.isEditing,
        pageBreak: card.pageBreak,
        hideSpaceBefore: card.hideSpaceBefore,
        hideSpaceAfter: card.hideSpaceAfter,
        data: {
          selectedView: card.data.selectedView,
          thirdPartyDataCardSettings: card.data.thirdPartyDataCardSettings,
          settings: card.data.settings,
          dataSelections,
          groupingSelection: defined(card.data.groupingSelection)
            ? mapDataSelection(card.data.groupingSelection)
            : undefined,
          geoSelections: card.data.geoSelections,
          lockToLatestTime: card.data.lockToLatestTime,
          timeSelection: card.data.timeSelection,
        },
      };
    }
    case "microCard":
      return {
        type: card.type,
        id: card.id,
        label: card.label,
        isEditing: card.isEditing,
        pageBreak: card.pageBreak,
        hideSpaceBefore: card.hideSpaceBefore,
        hideSpaceAfter: card.hideSpaceAfter,
        data: {
          thirdPartyMicroCardSettings: card.data.thirdPartyMicroCardSettings,
          mapLocationBounds: card.data.mapLocationBounds,
          settings: card.data.settings,
          selectedTab: card.data.selectedTab,
          lockToLatestTime: card.data.lockToLatestTime,
          geoSelections: microGeoSelectionsToStoredFormat(
            card.data.geoSelections
          ),
          dataSelections: card.data.dataSelections?.map(
            microDataSelectionToDto
          ),
          filterMeasures: card.data.filterMeasures.map((f) => ({
            id: f.id,
            measureSelection: defined(f.measureSelection)
              ? {
                  computedMeasurementType:
                    f.measureSelection.measure.computed?.type,
                  selectedComputedVariables:
                    f.measureSelection.computedMeasureVariablesConfig,
                  selectedDimensions: f.measureSelection.selectedDimensions,
                  measureId: f.measureSelection.measure.id,
                }
              : undefined,
            subjectPath: f.subjectPath,
            filterSet: f.filterSet,
          })),
        },
      };
    case "textCardSimple":
      return {
        type: card.type,
        id: card.id,
        label: card.label,
        isEditing: card.isEditing,
        data: card.data,
        pageBreak: card.pageBreak,
      };
    case "textCardCK":
      return {
        type: card.type,
        id: card.id,
        label: card.label,
        isEditing: card.isEditing,
        data: card.data,
        pageBreak: card.pageBreak,
        hideSpaceBefore: card.hideSpaceBefore,
        hideSpaceAfter: card.hideSpaceAfter,
      };
    case "microCardImage":
      throw new Error(
        "microCardImage should exist only in packaged doc data, not in workspace"
      );
    case "pythonCard":
      const surveyDataSelection = defined(card.data.surveySelection)
        ? mapDataSelection(card.data.surveySelection.selection)
        : undefined;

      return {
        type: card.type,
        id: card.id,
        label: card.label,
        isEditing: card.isEditing,
        data: {
          selectedView: card.data.selectedView,
          pythonCode: card.data.pythonCode,
          surveySelection:
            defined(card.data.surveySelection) && defined(surveyDataSelection)
              ? {
                  selection: surveyDataSelection,
                  timeSelection: card.data.surveySelection.timeSelection,
                }
              : undefined,
          columns: card.data.columns.map<
            WorkspacePythonCardV8["data"]["columns"][0]
          >((c) => {
            switch (c.type) {
              case "stats": {
                const measureSelection = c.selection.measureSelection;
                if (!defined(measureSelection)) {
                  throw new Error("Measure selection not defined");
                }
                return {
                  type: "stats",
                  columnName: c.columnName,
                  dataId: measureSelection.measure.data_id,
                  geoSelections: c.geoSelections,
                  timeSelection: c.timeSelection,
                  lockToLatestTime: c.lockToLatestTime,
                  selection: {
                    measureType: measureSelection.measure.value_type,
                    measureId: measureSelection.measure.data_id,
                    breakdownSelection: measureSelection.breakdowns,
                  },
                };
              }
              case "micro": {
                return {
                  type: "micro",
                  columnName: c.columnName,
                  selection: microDataSelectionToDto(c.selection),
                  timeSelection: c.timeSelection,
                  lockToLatestTime: c.lockToLatestTime,
                };
              }
              case "user-defined": {
                return {
                  columnName: c.columnName,
                  type: "user-defined",
                  expression: c.expression,
                };
              }
              default:
                assertNever(c);
            }
          }),
        },
        pageBreak: card.pageBreak,
        hideSpaceBefore: card.hideSpaceBefore,
        hideSpaceAfter: card.hideSpaceAfter,
      };
    case "error":
      return;
  }
  assertNever(card);
}

function microDataSelectionToDto(s: DataSelectionMicro): DataSelectionMicroDto {
  switch (s.type) {
    case "geo-micro":
      return {
        type: "geo-micro",
        id: s.id,
        measureId: s.measure?.id,
        selectedDimensions: s.selectedDimensions,
        subjectPath: s.subjectPath,
        timeSelection: s.timeSelection,
      };
    case "primary":
      return {
        type: "primary",
        id: s.id,
        measureId: s.measure?.id,
        computedMeasurementType: s.measure?.computed?.type,
        selectedComputedVariables: s.computedMeasureVariablesConfig,
        selectedDimensions: s.selectedDimensions,
        subjectPath: s.subjectPath,
        multiSelectEnabled: s.multiSelectEnabled,
        timeSelection: s.timeSelection,
        filterSet: s.filterSet,
      };
  }
}

function microGeoSelectionsToStoredFormat(
  selections?: MicroGeoSelections
): GeoSelectionsMicroDto | undefined {
  if (!defined(selections)) {
    return undefined;
  }
  if (selections.type === "deso") {
    return {
      type: "deso",
      selected: selections.selected.map((s) => {
        if (s.type !== "deso") {
          throw new Error("Unexpected type: " + s.type);
        }
        return {
          type: "deso",
          props: {
            deso: s.props.deso,
            deso_label: s.props.deso_label,
            regso: s.props.regso,
            regso_label: s.props.regso_label,
          },
        };
      }),
    } as GeoSelectionsMicroDto;
  } else if (selections.type === "regso") {
    return {
      type: "regso",
      selected: selections.selected.map((s) => {
        if (s.type !== "regso") {
          throw new Error("Unexpected type: " + s.type);
        }
        return {
          type: "regso",
          props: {
            regso: s.props.regso,
            regso_label: s.props.regso_label,
          },
        };
      }),
    };
  }
}

export function emptyWorkspace(): WorkspaceLatest {
  return {
    workspaceVersion: CURRENT_WORKSPACE_VERSION,
    state: {
      cards: [],
      reportMeta: reportMetaPersistentDefault(),
      colorSchemes: [],
      cardColorSchemes: [],
    },
  };
}

export function isTemplateCard(card: DocCardStats): boolean {
  return card.data.dataSelections.some(
    (d) =>
      defined(d.measureSelection) &&
      measureSelectionIsRegular(d.measureSelection) &&
      d.measureSelection?.measure.is_member_org_measurement_parent
  );
}

export function stateToThirdPartyDocTemplate(
  data: DocCardState[],
  reportMeta: ReportMetaPersistentSharing
) {
  const cards = [];
  const placeholderInfo = [];

  for (const c of data) {
    if (c.type === "dataCard") {
      const parentMeasureSelection = c.data.dataSelections.find(
        (d) =>
          defined(d.measureSelection) &&
          measureSelectionIsRegular(d.measureSelection) &&
          d.measureSelection?.measure.is_member_org_measurement_parent
      );
      if (defined(parentMeasureSelection)) {
        if (c.data.dataSelections.length > 1) {
          throw new Error(
            "Cannot have more than 1 measure in a card using member org measures"
          );
        }

        const templated = cardToThirdPartyDocTemplateCard(c);
        cards.push(templated.card);
        placeholderInfo.push(templated.placeholderInfo);
        continue;
      }
    }

    cards.push(cardToWorkspaceCardLatest(c));
  }

  const colorSchemesDataCards: ColorSchemeContainer[] = [];
  const colorSchemeLinksDataCards: CardColorSchemeLink[] = [];
  const styleContainersGeoMicro: StyleContainerGeoMicro[] = [];
  const styleContainerLinksGeoMicro: CardStyleContainerGeoMicroLink[] = [];

  for (const c of data) {
    switch (c.type) {
      case "dataCard": {
        const colorScheme = cardColors(c);
        const schemeId = uuidv4();
        if (defined(colorScheme)) {
          colorSchemesDataCards.push({
            // If a card is a template card, we empty the color scheme because the keys would be invalid anyway after
            // instantiating the template card with actual measure data
            ...(isTemplateCard(c)
              ? { ...colorScheme, colorScheme: {} }
              : colorScheme),
            id: schemeId,
          });
          colorSchemeLinksDataCards.push({
            cardId: c.id,
            schemeId: schemeId,
          });
        }
        break;
      }
      case "microCard": {
        const colorScheme = cardColors(c);
        const schemeId = uuidv4();
        if (defined(colorScheme)) {
          colorSchemesDataCards.push({ ...colorScheme, id: schemeId });
          colorSchemeLinksDataCards.push({
            cardId: c.id,
            schemeId: schemeId,
          });
        }

        const colorSchemeGeoMicro: StyleContainerGeoMicro | undefined =
          getCardColorsMicro(c);
        const styleContainerId = uuidv4();
        if (defined(colorSchemeGeoMicro)) {
          styleContainersGeoMicro.push({
            ...colorSchemeGeoMicro,
            id: styleContainerId,
          });
          styleContainerLinksGeoMicro.push({
            cardId: c.id,
            styleContainerId: styleContainerId,
          });
        }
        break;
      }
    }
  }

  return {
    placeholderInfo,
    workspaceVersion: CURRENT_WORKSPACE_VERSION,
    state: {
      cards: cards,
      reportMeta,
      colorSchemes: colorSchemesDataCards,
      cardColorSchemes: colorSchemeLinksDataCards,
      styleContainerLinksGeoMicro,
      styleContainersGeoMicro,
    },
  };
}

export function stateToWorkspaceLatest(
  data: DocCardState[],
  reportMeta: ReportMetaPersistentSharing
): WorkspaceLatest {
  const cards = data
    .map<WorkspaceCardLatest | undefined>(cardToWorkspaceCardLatest)
    .filter(defined);

  const colorSchemesDataCards: ColorSchemeContainer[] = [];
  const colorSchemeLinksDataCards: CardColorSchemeLink[] = [];
  const styleContainersGeoMicro: StyleContainerGeoMicro[] = [];
  const styleContainerLinksGeoMicro: CardStyleContainerGeoMicroLink[] = [];
  for (const c of data) {
    switch (c.type) {
      case "dataCard": {
        const colorScheme = cardColors(c);
        const schemeId = uuidv4();
        if (defined(colorScheme)) {
          colorSchemesDataCards.push({ ...colorScheme, id: schemeId });
          colorSchemeLinksDataCards.push({
            cardId: c.id,
            schemeId: schemeId,
          });
        }
        break;
      }
      case "microCard": {
        const colorScheme = cardColors(c);
        const schemeId = uuidv4();
        if (defined(colorScheme)) {
          colorSchemesDataCards.push({ ...colorScheme, id: schemeId });
          colorSchemeLinksDataCards.push({
            cardId: c.id,
            schemeId: schemeId,
          });
        }

        const colorSchemeGeoMicro: StyleContainerGeoMicro | undefined =
          getCardColorsMicro(c);
        const styleContainerId = uuidv4();
        if (defined(colorSchemeGeoMicro)) {
          styleContainersGeoMicro.push({
            ...colorSchemeGeoMicro,
            id: styleContainerId,
          });
          styleContainerLinksGeoMicro.push({
            cardId: c.id,
            styleContainerId: styleContainerId,
          });
        }
        break;
      }
    }
  }

  return {
    workspaceVersion: CURRENT_WORKSPACE_VERSION,
    state: {
      cards: cards,
      reportMeta,
      colorSchemes: colorSchemesDataCards,
      cardColorSchemes: colorSchemeLinksDataCards,
      styleContainerLinksGeoMicro,
      styleContainersGeoMicro,
    },
  };
}

function getCardColorsMicro(
  card: DocCardMicro
): StyleContainerGeoMicro | undefined {
  const mapState = card.data.loadedData?.microMapState;
  const styles: StyleContainerGeoMicro["styles"] = [];
  mapState?.loadedLines?.forEach((l) => {
    styles.push({
      dataGroupId: l.id,
      geometry: l.type,
      label: l.label,
      border: l.style.border,
      lineDashArray: l.style.lineDashArray,
      fill: l.style.fill,
    });
  });
  mapState?.loadedPoints?.forEach((l) => {
    styles.push({
      dataGroupId: l.id,
      geometry: l.type,
      label: l.label,
      border: l.style.border,
      fill: l.style.fill,
      fillOpacity: l.style.fillOpacity,
    });
  });
  mapState?.loadedPolygons?.forEach((l) => {
    styles.push({
      dataGroupId: l.id,
      geometry: l.type,
      label: l.label,
      border: l.style.border,
      fill: l.style.fill,
      lineDashArray: l.style.lineDashArray,
      fillOpacity: l.style.fillOpacity,
    });
  });
  return { id: card.id, styles };
}

function mapDataSelection(
  selection: DataSelection | DataSelectionWithoutId
): DataSelectionMinimal | undefined {
  const measureSelection = selection.measureSelection;
  if (!defined(measureSelection)) {
    return;
  }
  return {
    measureType: measureSelection.measure.value_type,
    measureId: measureSelection.measure.data_id,
    breakdownSelection: measureSelection.breakdowns,
  };
}

export interface MeasureInfo {
  availableMeasures: MeasureDto[];
  measure: MeasureDto;
  subjectPath: string[];
}
